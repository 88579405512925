import { LitElement, html, css } from "lit";

class ProteksiLanding extends LitElement {
  static styles = css`
    .section-proteksi-landing {
      display: flex;
      justify-content: center;
    }

    .container-proteksi-landing {
      margin: 64px 10% 10px;
      width: 1440px;
      color: #424242;
    }
  `;

  render() {
    return html`
      <div class="section-proteksi-landing">
        <div class="container-proteksi-landing">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

customElements.define("proteksi-landing", ProteksiLanding);
