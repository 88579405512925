import { LitElement, html, css, unsafeCSS } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import ovalRight from '../../assets/images/oval-right.png'

class InsuranceAvailableFeature extends LitElement {
    static get properties() {
        return {
            availableFeature:{
                type: Object,
                attribute: 'available-feature'
            }
        }
    }
    constructor() {
        super();
    }

    static styles = css`
        .corp-feature-container{
            margin: 0px 0px -70px auto;
            text-align: center;
            padding-bottom: 45px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right bottom;
            background-image: url('${unsafeCSS(ovalRight)}');
            background-origin: content-box;
        }
        .main-cont{
            background: rgba(229, 239, 255, 0.4);
        }
        .feature-header{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #424242;
            padding-top: 90px;
        }
        .feature-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto 92px;
            // margin-bottom: 92px;
        }
        .list-feature{
            display: inline-table;
            flex-basis: 40%;
            margin-top: 80px;
        }
        .feature-image{
            width: 51px;
            height: 51px;
            margin-bottom: 16px;
        }
        .feature-title{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            margin: 16px 0 8px;
        }
        .feature-subtitle{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #7F7F7F;
            width: 50%;
            margin: 0 auto;
        }
        
        .insurance-price-group{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            background-color: white;
            border: none;
            cursor: pointer;
        }
        .insurance-exclusion{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            background-color: white;
            border: none;
            cursor: pointer;
        }
        .price-group-container{
            display: none;
            position: fixed; /* Stay in place */
            z-index: 200; /* Sit on top */
            justify-content: center;
            align-items: center;
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        }
        .price-group-modal{
            width: 690px;
            height: fit-content;
            background: #FFFFFF;
            border-radius: 5px;
            justify-content: center;
            padding: 17px 45px 62px 45px;
            text-align: center;
        }
        .table-container{
            justify-content: center;
            display: flex;
        }
        .table-price-group {
            width: 930px;
            height: 517px;
            border-spacing: 0px;
            border-bottom: 1px solid #92B9FA;
        }
        .row-1 {
            width: 186px;
            cellspading: 24px 32px;
            border: 1px solid #92B9FA;
            text-align: center;
        }
    `

    render() {
        return html`
            <div class="main-cont">
                <div class="corp-feature-container" >
                    <div class="feature-header">${this.availableFeature.title}</div>
                    <div class="feature-container">
                        ${repeat(
                            this.availableFeature.list_feature,
                            (availableFeature) => html`
                            <div class="list-feature">
                                <img class="feature-image" src="${availableFeature.icon}">
                                <div class="feature-title">${availableFeature.title}</div>
                                <div class="feature-subtitle">${availableFeature.description}</div>
                            </div>
                        `
                        )}
                    </div>
                </div>
            </div>
            
        `;
    }

}

customElements.define('insurance-available-feature', InsuranceAvailableFeature);


