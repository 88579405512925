import { LitElement, html, css } from 'lit';
import { unsafeHTML } from 'lit-html/directives/unsafe-html'

class InsuranceRiderModal extends LitElement {
    static properties = {
        isShown: {
            default: 'false'
        },
        riderBenefits: {
            type: Array,
        }
    }

    static styles = css`
        .modal-container {
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(0,0,0);
            background-color: rgba(0,0,0,0.4);
            transition: visibility 0.5s, opacity 0.2s;
        }

        .modal {
            background-color: #ffffff;
            width: 53.8%;
            border-radius: 8px;
            min-width: 744px;
        }

        .modal-container[aria-hidden="true"] {
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
        }
      
        .modal-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-grow: 0;
            gap: 10px;
            padding: 24px;
        }

        .title {
            font-size: 18px;
            line-height: 24px;
            flex: none;
            order: 0;
            flex-grow: 1;
        }
      
        .close-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
        }
      
        .close-button:focus {
            outline: #3570D2 5px auto;
        }
        
        .modal-body {
            display: flex;
            padding: 8px 24px 24px;
        }

        .rider-table {
            border-spacing: 0;
            table-layout: fixed;
            width: 100%;
            margin: 0px;
            font-size: 16px;
            color: #424242;
        }

        .rider-table > thead th {
            background: #E5EFFF;
        }

        .rider-table > tbody td,
        .rider-table > thead th {
            border-right: 1px solid #92B9FA;
            border-top: 1px solid #92B9FA;
            padding: 16px 0;
            line-height: 22px;
        }

        table tr:last-child td {
            border-bottom: 1px solid #92B9FA;
        }

        .rider-table td.price-cell {
            text-align: center;
            font-size: 14px;
        }

        .rider-table td.price-cell b {
            font-size: 16px;
        }

        .rider-table td.type-cell {
            font-weight: bold;
            padding: 0 32px;
            border-left: 1px solid #92B9FA;
        }

        table tr th:first-child {
            width: 130px;
            border-top-left-radius: 8px;
            border-left: 1px solid #92B9FA;
            text-align: left;
            padding-left: 32px;
        }

        table tr:last-child td:first-child {
            border-bottom-left-radius: 8px;
        }
      
        table tr:last-child td:last-child {
            border-bottom-right-radius: 8px;
        }
        
        table tr th:last-child {
            border-top-right-radius: 8px;
        }
    `

    handleShowModal(event) {
        this.isShown = !this.isShown;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    render() {
        return html`
            <div class="modal-container" aria-hidden="${!this.isShown}" role="dialog">
                <div class="modal">
                    <div class="modal-header">
                        <div class="title">Detail Manfaat Rider</div>
                        <button type="button" class="close-button" @click="${this.handleShowModal}">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.99998 7.17849L10.714 11.8925L11.8925 10.714L7.17849 5.99998L11.8925 1.28593L10.714 0.107422L5.99998 4.82147L1.28593 0.107424L0.107423 1.28594L4.82147 5.99998L0.107422 10.714L1.28593 11.8925L5.99998 7.17849Z" fill="#424242"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="rider-table">
                            <thead>
                                <tr>
                                    <th>Tipe Rider</th>
                                    <th>Limit Coverage per karyawan</th>
                                    <th>Premi</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this.riderBenefits?.map((item) => html`
                                    <tr>
                                        <td class="type-cell">${item.rider_type}</td>
                                        <td class="price-cell">${unsafeHTML(item.coverage_limit)}</td>
                                        <td class="price-cell">${unsafeHTML(item.premi)}</td>
                                    </tr>
                                `)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        `
    }
}

customElements.define('insurance-rider-modal', InsuranceRiderModal);