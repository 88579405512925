import { LitElement, html, css, unsafeCSS } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import circleBlob from '../../assets/images/spiral-background-blue.png'
import roundedBlue from '../../assets/images/rounded-blue-background.png'

class InsuranceCorporateBenefit extends LitElement {
    static get properties() {
        return {
            corporateBenefitList:{
                type: Object, 
                attribute: "corporate-benefit-list"
            }
        }
    }

    constructor() {
        super();
    }

    static styles = css`
        .rounded-img{
            width: 100%;
        }
        .corp-benefit-container{
            margin: 0 auto;; 
            text-align: center;
            background: #3570D2;
            padding-bottom: 80px;
            background-image:url('${unsafeCSS(circleBlob)}');
            background-repeat: no-repeat;
            background-size: 33%;
            background-position: left 180%;
        }
        .benefit-header{
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 42px;
            margin-top: -20px;
            color: #FFFFFF;
        }
        .benefit-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 31px;
        }
        .benefit-type-container{
            width: 100%;
            margin-top: 100px;
            background: rgba(0, 58, 158, 0.2);
            border-radius: 12px;
            padding: 82px 82px 40px;
            height: fit-content;
            width: 30%;
        }   
        .list-benefit{
            flex-basis: 30%;
            margin-bottom: 24px;
            padding: 24px 20px 32px;
            background: #FFFFFF;
            border-radius: 8px;
        }
        .icon-benefit{
            display: flex;
        }
        .benefit-type{
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #FFFFFF;
            margin-bottom: 32px;
        }
        .benefit-image{
            width: 120px;
            height: 120px;
        }
        .benefit-title{
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            margin: 16px 0 8px;
        }
        .item-benefit{
            text-align: left;
            margin-left: 15px;
        }
        .benefit-subtitle{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #7F7F7F;
        }
        .icon-line{
            margin-top: 32px;
            margin-bottom: 0;
            width:130px;
            border: 2px solid #FFFFFF;
            border-radius: 5px;
        }
    `;
    
    render() {
        return html`
            <img class="rounded-img" src="${roundedBlue}">
            <div class="corp-benefit-container"> 
                <div class="benefit-header">${this.corporateBenefitList.title}</div>
                <hr class="icon-line">
                <div class="benefit-container">
                    ${repeat(
                        this.corporateBenefitList.benefit_detail,
                        (availablebenefit) => html`
                        <div class="benefit-type-container">
                            <div class="benefit-type">${availablebenefit.benefit_type}</div>
                            ${repeat(
                                availablebenefit.benefit_list,
                                (benefit_list) => html`
                                    <div class="list-benefit">
                                        <div class="icon-benefit">
                                            <img class="benefit-image" src="${benefit_list.icon}">
                                            <div class="item-benefit">
                                                <div class="benefit-title">${benefit_list.title}</div>
                                                <div class="benefit-subtitle">${benefit_list.description}</div>
                                            </div>
                                        </div>
                                    </div>
                                `
                            )}
                        </div>
                        
                    `
                    )}
                </div>
            </div>
        `;
    }

}

customElements.define('insurance-corporate-benefit', InsuranceCorporateBenefit);


