import { LitElement, html, css } from "lit";
import "./consult-form-corporate.js";
import successIcon from "../../assets/images/success-icon.svg";
import { Aloproteksi } from '../lib/aloproteksi';

class LandingBanner extends LitElement {
  static get properties() {
    return {
      showModal: {
        type: Boolean,
        state: true
      },
      banner: {
        type: String,
        attribute: "background-desktop",
      },
      isShowSuccessModal: {
        type: Boolean
      },
      linkCalendly: {
        type: String,
        attribute: 'link-calendly'
      }
    };
  }

  constructor() {
    super();
    this.showModal = false;
  }

  openModal() {
    
    this.showModal = !this.showModal;
    Aloproteksi.sendEventGA4({
     event: "get_consult"
    });
  }

  static styles = css`
    * {
      margin: 0;
      padding: 0;
      border: 0;
      box-sizing: border-box;
    }

    *,
    ::before,
    ::after {
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
      border-color: #ececec;
    }

    .banner-img {
      width: 1440px;
    }

    .button-container {
      display: flex;
      position: absolute;
      margin: -200px 0px 0px 315px;
    }

    .consultation-btn {
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding: 12px 20.5px;
      width: fit-content;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-right: 12px;
      color: #ffffff;
      cursor: pointer;
    }

    .offer-btn {
      background: #ed6700;
      border-radius: 8px;
      padding: 12px 20.5px;
      width: fit-content;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;
      border: 0;
    }
    .img-cont{
      background: linear-gradient(rgb(53, 112, 210) 0%, rgb(35, 87, 177) 100%);
      text-align: center;
    }

    @media (min-width: 1200px) and (max-width: 1330px) {
      .button-container {
        margin: 0;
        left: 76px;
        top: 486px;
      }
    }

    @media (max-width: 1440px){
      .button-container{
        margin: -200px 0px 0px 102px;
      }
    }

    @media (min-width: 760px) and (max-width: 1200px) {
      .button-container {
        margin: 0;
        left: 76px;
        top: 386px;
      }
    }

    @media (max-width: 760px) {
      .button-container {
        margin: 0;
        left: 56px;
        top: 264px;
      }
    }
  `;

  redirectToSimulation() {
    const currentOrigin = window.location.origin;
    const simulationPath = "/simulations";
    const urlSimulation = `${currentOrigin}${simulationPath}`;
    window.location.href = urlSimulation;
    Aloproteksi.sendEventGA4({
      event: "quotation_get",
      entry_point: "main_banner"
    });
  }

  render() {
    return html`
      <div class="img-cont">
        <img class="banner-img" src="${this.banner}" />
        <div class="button-container">
          <div class="consultation-btn" @click="${this.openModal}">
            Konsultasi Dulu
          </div>
          <button class="offer-btn" @click="${this.redirectToSimulation}">
            Dapatkan Penawaran
          </button>
        </div>
      </div>
      
      
      <consult-form-corporate .linkCalendly="${this.linkCalendly}" .showModal=${this.showModal}></consult-form-corporate>
    `;
  }
}

customElements.define("landing-banner", LandingBanner);
