import { LitElement, html, css } from "lit";

class InsuranceSectionContact extends LitElement {
    static get properties() {
        return {

        }
    }

    static styles = css`
    .text-header{
        display: flex;
        justify-content: center;
    }
    .main-container-contact{
        margin-top: 32px;
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }
    .container-inner{
        width: 543px;
        box-shadow: 0px 1px 10px rgba(66, 66, 66, 0.15);
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
    }
    .text-title{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #3570D2;
        margin-bottom: 10px;
    }
    .text-desc{
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #7F7F7F;
        width: 310px;
    }
    .button{
        border: 1px solid #3570D2;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 16px;
        line-height:22px;
        display: flex;
        color: #3570D2;
        padding: 12px 24px;
        text-decoration: none;
    }
    .icon-contact{
        width: 58px;
        height: 58px;
        background-size: contain;
        border-radius: 50%;
        margin-right: 16px;
    }
    `;

    render() {
        return html`
        <div class="text-header">Anda Butuh Bantuan?</div>
        <div class="main-container-contact">
            <div class="container-inner">
                <img class="icon-contact" src="http://res.cloudinary.com/dk0z4ums3/image/upload/v1665047937/insurance_templates/product_benefit_image_MCQ.png">
                <div class="contact-center">
                    <div class="text-title">Chat Kami di Whatsapp</div>
                    <div class="text-desc">Alodokter siap menjawab pertanyaan Anda melalui chat</div>
                </div>
                <a on-click="sendGA" class="button" target="blank" href="https://wa.me/6281288880256?text=Alo%2C+saya+ingin+bertanya+tentang+Proteksi">Chat Sekarang</a>
            </div>
        </div>
        `;
    }
}

customElements.define('insurance-section-contact', InsuranceSectionContact);

