import { LitElement, html, css, unsafeCSS } from "lit";
import shieldProtection from '../../assets/images/shield-protection.svg';
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceCommonDisease extends LitElement {
static properties = {
        exclusionInformation: {
            type: String,
            attribute: 'exclusion-information'
        },
        commonDiseaseUrl: {
            type: String,
            attribute: 'common-diseases'
        }
    }
    
    static styles = css`
        .background-shield {
            background: linear-gradient(277.65deg, #FFFFFF 75.43%, #EBF1FF 98.29%);
            border-radius: 8px;
            width: 37%;
            border: 1px solid #ECECEC;
            margin: 0 auto 90px auto;
        }

        .container {
            display: flex;
            flex-direction: column;
            padding: 24px;
            gap: 16px;
            background-image: url(${unsafeCSS(shieldProtection)});
            background-repeat: no-repeat;
            background-position: right -2% top -100%;
        }
    
        .title {
            color: #424242;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            margin: 0;
        }
    
        .description {
            color: #424242;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
        }
    
        .link {
            color: #3570D2;
            text-decoration: none;
        }
    `
    trackGtm(){
        Aloproteksi.sendEventGA4({
            event: "view_benefit_exclusion",
        });
    }

    render() {
        return html`
            <div class="background-shield">
                <div class="container">
                    <h1 class="title">Aloproteksi Menanggung Penyakit & Tindakan Medis Terlengkap</h1>
                    <p class="description">Untuk melihat lebih detail daftar penyakit yang dicover Aloproteksi dapat dilihat <a class="link" href="${this.commonDiseaseUrl}" target="_blank">di sini</a> dan pengecualian bisa dilihat <a class="link" href="${this.exclusionInformation}" @click="${this.trackGtm}" target="_blank">di sini.</a></p>
                </div>
            </div>
        `
    }
}

customElements.define('insurance-common-disease', InsuranceCommonDisease);