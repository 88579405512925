import { LitElement, html, css } from 'lit';
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceWhatsappFloatingButton extends LitElement {
    static get properties() {
        return {
            contactSupport:{
                type: Object, 
                attribute: "contact-support"
            }
        }
    }
    constructor() {
        super();
    }

    static styles = css`
        .button {
            position: fixed;
            z-index: 2;
            text-decoration: none;
            display: inline-flex;
            border: 0;
            color: #FFFFFF;
            cursor: pointer;
            width: 191px;
            height: 52px;
            bottom: 16px;
            transform: translateX(-50%);
            background: #ECF9EB;
            right: -4%;
            border-radius: 100px;
        }

        .icon {
            float: right;
            width: 27px;
            height: 27px;
            margin-left: 9px;
        }

        .title-whatsapp {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #212121;
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            padding: 9px;
        }

        .desc-whatsapp {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            color: #212121;
        }

        @media only screen and (max-width: 767px) {
            .button.link-button.link-whatsapp {
            width: calc(100% - 32px);
            }

            .button.link-button.link-whatsapp img {
            width: 100%;
            float: right;
            }

            .button{
                right: -10%;
            }
        }

        @media only screen and (max-width: 390px){
            .button{
                right: -20%;
            }
        }
    `;

    render() {
        return html`
        <div id="linkButton" class="button" url="${this.contactSupport.phone_number}" @click="${this._openUrl}">
            <div class="container">
                <div class="text-container">
                    <div class="title-whatsapp">Butuh Bantuan?</div>
                    <div class="desc-whatsapp">Chat kami di Whatsapp</div>
                </div>
                <img class="icon" src="${this.contactSupport.whatsapp_image_url}">
            </div>
        </div>
        `;
    }

    _openUrl(e){
        let href = e.currentTarget.getAttribute('url')
        window.open(href, '_blank')

        let element = "wa_floating_button_landing"
        if (window.location.href.includes('/simulations')) {
            element = "wa_floating_button_quotation"
        } 
        Aloproteksi.sendEventGA4({
            event: "click_element",
            element: element
        });
    }

}

customElements.define('insurance-floating-whatsapp', InsuranceWhatsappFloatingButton);


