import { LitElement, html, css, unsafeCSS } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import eclipseRight from '../../assets/images/circle-dot-right.svg'
import eclipseLeft from '../../assets/images/circle-dot-left.svg'

class InsuranceCounterMember extends LitElement {
    static get properties() {
        return {
            counterMember:{
                type: Object,
                attribute: 'counter-member'
            },
            certification: {
                type: String,
                attribute: 'certification-desktop'
            }
        }
    }

    static styles = css`
        .counter-member-container{
            margin: 0 auto;
                /* display: flex;
                justify-content: space-between; */
            width: 75%; 
            padding: 57px 56px 143px 56px;
        }
        
        .member-title{
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            margin: 157px auto 91px;
            color: #424242;
            text-align: center;
            width: 40%;
        }

        .bg-eclipse-right {
            background-image: url(${unsafeCSS(eclipseRight)});
            background-repeat: no-repeat;
            background-position: 90% top;
        }

        .bg-eclipse-left {
            background-image: url(${unsafeCSS(eclipseLeft)});
            background-repeat: no-repeat;
            background-position: 10% bottom;
        }

        .list-member-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        .number{
            font-weight: 700;
            font-size: 60px;
            line-height: 72px;
            text-align: center;
            background: linear-gradient(270deg, #FAD961 0%, #F76B1C 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

        }

        .subtitle{
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #424242;

        }

        .sub-number-container{
            flex-basis: 50%;
            text-align: center;
            margin-bottom: 62px;

        }

        .title-social-proof{
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #424242;
            text-align: center;
            margin-bottom: 119px;
            margin-top: 44px;
        }

        .product-aloproteksi{
            display: flex;
            flex-direction: row;
        }

        .certification-img {
            width: 100%;
            margin-left: 20px;
        }

        hr{
            border: 1px solid #ECECEC;
        }
    `;    
    
    constructor() {
        super();
    }

    render() {
        return html`
        <div class="member-title">${this.counterMember.title}</div>
            <div class="bg-eclipse-right">
                <div class="bg-eclipse-left">
                    <div class="counter-member-container">
                        <div class="list-member-container">
                            ${repeat(
                                this.counterMember.list_number,
                                (counterNumber) => html`
                                    <div class="sub-number-container">
                                        <div class="number">${counterNumber.total}</div>
                                        <div class="subtitle">${counterNumber.subtitle}</div>
                                    </div>  
                                `
                            )}
                        </div>
                        <hr>
                        <div class="title-social-proof">Aloproteksi melalui Alodokter telah mengantongi</div>
                        <div class="product-aloproteksi">
                            <img class="certification-img" src="${this.certification}"/>
                        </div>  
                    </div>
                </div>
            </div>
        `;
    }

}

customElements.define('insurance-counter-member', InsuranceCounterMember);


