import { LitElement, html, css } from 'lit'
import icon_fb from '../../assets/images/facebook.svg'
import icon_twitter from '../../assets/images/twitter.svg'
import icon_linkedin from '../../assets/images/linkedin.svg'
import icon_instagram from '../../assets/images/instagram.svg'
import icon_youtube from '../../assets/images/youtube.svg'

class FooterView extends LitElement {
  static properties = {
    date: {
      type: Object,
    },
    sosmedItems: {
      type: Array,
    },
    referralCodeControl: {
      type: Object,
      attribute: "referral-code-control"
    }
  }
  constructor() {
    super()
    this.date = new Date()
    this.sosmedItems = [
      {
        url: 'https://www.facebook.com/alodokter',
        title: 'Facebook Alodokter',
        image: icon_fb,
        target: '_blank',
      },
      {
        url: 'https://www.twitter.com/alodokter',
        title: 'Twitter Alodokter',
        image: icon_twitter,
        target: '_blank',
      },
      {
        url: 'https://www.linkedin.com/company/alodokter',
        title: 'Linkedin Alodokter',
        image: icon_linkedin,
        target: '_blank',
      },
      {
        url: 'https://www.instagram.com/alodokter_id',
        title: 'Instagram Alodokter',
        image: icon_instagram,
        target: '_blank',
      },
      {
        url: 'https://www.youtube.com/channel/UCVlQXBPDCbz5YES_gBpC1rg',
        title: 'Youtube Alodokter',
        image: icon_youtube,
        target: '_blank',
      },
    ]
  }
  static get template() {}

  static styles = css`
    .footer {
      background-color: #3570d2;
      margin: 0 auto;
      margin-top: 80px;
    }

    /* clear margin for diffrent background color */
    .footer.clear-margin {
      margin-top: 0;
    }

    .footer-inner {
      padding-top: 58px;
    }

    .footer a {
      text-decoration: none;
      color: #fff;
      margin-right: 23px;
      margin-bottom: 10px;
    }

    .container-footer {
      width: 1080px;
      margin: 0 auto;
    }

    .footer-top .footer-data-item {
      display: inline-block;
      width: 330px;
      vertical-align: top;
      font-size: 18px;
      margin-bottom: 30px;
    }

    .footer-data-item h4 {
      color: #fff;
      font-size: 20px;
      margin: 0;
    }

    .footer-data-item ul {
      list-style-type: none;
      padding: 0;
    }

    .footer-data-item li {
      margin: 10px auto;
    }

    .footer-data-item li a {
      position: relative;
    }

    .footer-data-item li a:before {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      content: '';
      width: 0%;
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .footer-data-item li a:hover:before {
      width: 100%;
    }

    .footer-data-title {
      color: #fff !important;
      font-size: 18px;
      font-weight: bold;
      display: block;
    }

    .footer-data-title.alo-group {
      font-size: 20px;
      margin: 41px 0 12px 0;
    }

    .social-media-link img {
      width: 39px;
      height: 39px;
    }

    .footer-row {
      margin: 14px 0;
      display: flex;
      justify-content: space-between;
    }

    .footer-copyright {
      border-top: solid 1px #fff;
      display: flex;
      justify-content: space-between;
      color: #fff;
      width: 1110px;
    }

    .socmedList {
      max-width: 260px;
    }

    .alo-group-link {
      margin-right: 20px;
    }

    .banner-footer {
      padding: 24px 0 21px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1c4b9a+0,6d97de+83 */
      background: #1c4b9a;
      /* Old browsers */
      background: -moz-linear-gradient(left, #1c4b9a 0%, #6d97de 83%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #1c4b9a 0%, #6d97de 83%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(189deg, #6d97de, #1c4b9a);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1c4b9a', endColorstr='#6d97de', GradientType=1);
      /* IE6-9 */
      box-shadow: 25px -26px 160px inset #1c4b9a;
    }

    .image_phone {
      max-width: 237px;
      height: auto;
      position: absolute;
      bottom: -20px;
      left: 0;
    }

    .text-banner {
      margin-left: 270px;
      color: #fff;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 34px;
    }

    .text-banner h3,
    .text-banner h4 {
      margin: 0;
      font-size: 26px;
    }

    .text-banner h4 {
      font-weight: 100;
    }

    .flex-center {
      display: flex;
      position: relative;
    }

    .downloadApp {
      width: 150px;
    }

    .downloadApp a {
      display: block;
      margin: 0 0 10px 0;
    }

    .downloadApp a:last-child {
      margin: 0;
    }

    .alo-group span:first-child {
      margin-right: 25px;
    }

    .logo-kemenkes {
      font-size: 14px;
      line-height: 0.93;
      letter-spacing: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: -5px;
    }

    .logo-kemenkes img {
      margin-left: 7px;
      width: 100px;
      height: 43px;
    }

    @media only screen and (max-width: 1340px) {
      .footer {
        width: 1340px;
      }
    }

    .text-banner-3 {
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
    }

    .text-banner-4 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }
  `
  render() {
    return html`
        <div class="footer">
        <div class="container-footer">
            <div class="footer-inner">
                <div class="footer-top">
                    <div class="footer-data-item about-us">
                    <h4>Alodokter</h4>
                    <ul>
                    
                        <li><a href="https://www.alodokter.com/about" target="_blank">Tentang Kami</a></li>
                        <li><a href="https://www.kalibrr.id/id-ID/c/alodokter/jobs" target="_blank">Karier</a></li>
                        <li><a href="https://www.alodokter.com/hubungi-kami" target="_blank">Hubungi Kami</a></li>
                        <li><a href="https://www.alodokter.com/editorial" target="_blank">Tim  Editorial</a></li>
                        <li><a href="https://www.alodokter.com/alochat-unlimited" target="_blank">Langganan</a></li>
                        
                    </ul>
                    </div>
                    <div class="footer-data-item contact-us">
                    <h4>Lainnya</h4>
                    <ul>
                        <li><a href="https://www.alodokter.com/syarat-dan-ketentuan" target="_blank">Syarat & Ketentuan</a></li>
                        <li><a href="https://www.alodokter.com/privasi" target="_blank">Privasi</a></li>
                        <li><a href="https://www.alodokter.com/advertise" target="_blank">Iklan</a></li>
                        <li><a href="https://www.alodokter.com/join-doctor" target="_blank">Gabung di Tim Dokter</a></li>
                        <li><a href="https://program.alodokter.com/hospital-partnership/" target="_blank">Daftarkan Rumah Sakit Anda</a></li>
                        ${this.referralCodeControl.is_display ? html `
                          <li><a href="${this.referralCodeControl.link}" target="_blank">${this.referralCodeControl.title}</a></li>
                        `
                        : html ``}
                    </ul>
                    </div>
                    <div class="footer-data-item social-media">
                    <h4>Media Sosial</h4>
                    <div class="footer-row socmedList">
                        ${this.sosmedItems.map((item) => {
                          return html` <a
                            href="${item.url}"
                            rel="noopener"
                            target="${item.target}"
                            class="social-media-link"
                            title="${item.title}"
                          >
                            <img src="${item.image}" alt="${item.title}" />
                          </a>`
                        })}
                    </div>
                    <p class="footer-data-title alo-group">Bagian dari Alodokter</p>
                    <div class="alo-group">
                    <span on-click="trackGAAlomedika">
                        <a
                        href="https://www.alomedika.com"
                        rel="noopener"
                        target="_blank"
                        class="alo-group-link"
                        >alomedika.com</a
                        >
                    </span>
                    <span on-click="trackGAPobpad">
                        <a
                        href="https://www.pobpad.com"
                        rel="noopener"
                        target="_blank"
                        class="alo-group-link"
                        >pobpad.com</a
                        >
                    </span>
                </div>
                
              </div>
              <div class="footer-copyright">
                    <p>Hak Cipta © ${this.date.getFullYear()} Alodokter</p>
                </div>
            </div>
                </div>
            </div>
        </div>
        </div>
            
        `
  }
}
customElements.define('footer-view', FooterView)