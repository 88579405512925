import { html, LitElement, css, nothing, unsafeCSS } from "lit";
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { repeat } from 'lit/directives/repeat';
import ellipseComparisonBlue from '../../assets/images/ellipse-comparison-blue.svg'
import ellipseComparisonRed from '../../assets/images/ellipse-comparison-red.svg'
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceProductComparison extends LitElement {
    static properties = {
        isRiderPlanShown: {
            default: 'false'
        },
        comparisons: {
            type: Object,
            attribute: 'comparisons',
            default: {}
        },
        riderBenefits: {
            type: Array,
            attribute: 'rider-benefits'
        }
    }

    static styles = css`
        .background-blur-blue {
          background-image: url(${unsafeCSS(ellipseComparisonBlue)});
          background-repeat: no-repeat;
          background-position: 195% top;
        }
      
        .background-blur-red {
          background-image: url(${unsafeCSS(ellipseComparisonRed)});
          background-repeat: no-repeat;
          background-position: -85%;
        }
      
        .product-comparison {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;
            margin-bottom: 24px;
            margin-top: 120px;
            width: 1440px;
          }

        .product-comparison-title {
            margin-bottom: 36px;
            color: #424242;
        } 
      
        .product-comparison-divider {
            border: 2px solid #3570D2;
            width: 130px;
            border-radius: 1.5rem;
        }
      
        .product-comparison-table-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            margin-top: 70px;
        }

        .product-comparison-table {
            width: 75%;
            border-spacing: 0;
            table-layout: fixed;
        } 

        .product-comparison-table-title {
            color: #fff;
            padding: 0.75em 0 0.75em 0;
            background-color: #3570D2;
            border-right: 1px solid #92B9FA;
            border-left: 1px solid #92B9FA;
        }

        .product-comparison-image {
            width: 70%;
            margin-bottom: 30px;
        }

        .product-comparison-content {
            padding: 24px 32px 24px 32px;
            text-align: center;
            font-size: 16px;
            background-color: #fff;
            border: 1px solid #92B9FA;
            color: #424242
            line-height: 24px;;
        }

        .product-comparison-image {
            width: 70%;
            margin-bottom: 30px;
        }
      
        .border-radius-left {
            border-radius: 0 0 0 12px;
        }
      
        .border-radius-right {
            border-radius: 0 0 12px 0;
        }
      
        .no-border-y {
            border-top: 0;
            border-bottom: 0;
        }
      
        .product-comparison-content-title {
            font-size: 16px;
            text-align: left;
            color: #424242;
            margin: 0;
            font-weight: 700;
        }
      
        .product-comparison-content-subtitle {
            font-size: 14px;
            text-align: left;
            color: #7F7F7F;
            margin: 6px 0 0 0;
            font-weight: 400;
        }
      
        .product-comparison-table-badge {
            background-color: #ED6700;
            padding: 4px 12px;
            margin-left: 6px;
            border-radius: 100px;
            font-size: 14px;
            font-weight: bold;
        }
      
        .product-comparison-content > a {
            display: inline-flex;
            align-items: flex-end;
            gap: 3px;
            color: #3570D2;
            font-weight: bold;
            text-decoration: none;
        }
      
        .text-gray {
            color: #7F7F7F;
        }

        table:nth-child(2) tr:first-child th:first-child {
            border-top-left-radius: 10px;
        }

        table:nth-child(2) tr:first-child th:last-child {
            border-top-right-radius: 10px;
        }

        table:last-child tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }

        table:last-child tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }

        td:not(:last-child) {
            border-right: 0 !important;
        }

        table:not(:last-child) td {
            border-bottom: 0 !important;
        }
    `

    firstUpdated() {
        const linkElement = this.renderRoot.querySelector('td > a');
        const riderPlanModal = this.renderRoot.querySelector('insurance-rider-modal');
        linkElement.addEventListener('click', (event) => this.handleRiderPlan(event, riderPlanModal));
    }

    handleRiderPlan(event, riderPlanModal) {
        event.preventDefault();

        riderPlanModal.isShown = "true"
        Aloproteksi.sendEventGA4({
            event: "view_rider_information",
        });
    }

    crossIcon() {
        return html`
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="fill: #BCBCBC;">
                <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z"/>
            </svg>
        `
    }

    productImages() {
        return html`
            <table class="product-comparison-table">
                <tr>
                    <th></th>
                    ${repeat(this.comparisons['comparison_data'][0]['comparisons'][0]['products'], (product) => html`
                        <th>
                            <img class="product-comparison-image" src="${product['product_image']}" alt="Aloproteksi Product">
                        </th>
                    `)}
                </tr>
            </table>
        `
    }

    productCell(productComparison) {
        let productValues = [];
        const isMerge = productComparison["is_merge"];
        if (isMerge) {
            const values = productComparison['products'].map((product) => product.value);
            productValues = Array.from(new Set(values));
        }

        if (isMerge) {
            return html`
                <td class="product-comparison-content text-gray" colspan="${productComparison['products'].length}">
                    ${unsafeHTML(productValues[0])}
                </td>
            `
        } else {
            return productComparison['products']?.map((product) => {
                const isImage = /\.(jpg|jpeg|png|gif)$/i.test(product['value']);
                if (isImage) {
                  return html`
                    <td class="product-comparison-content">
                      <img src="${product['value']}" alt="${product['product_name']}" style="width: 23px; height: 23px;" />
                    </td>
                  `;
                } else {
                  return html`
                    <td class="product-comparison-content">
                      ${!product['value'] ? this.crossIcon() : html`<span>${unsafeHTML(product['value'])}</span>`}
                    </td>
                  `;
                }
            });
              
        }
    }

    render() {
        return html`
            <div class="background-blur-blue">
                <div class="background-blur-red">
                    <div class="product-comparison">
                        <h1 class="product-comparison-title">
                            ${this.comparisons['title_comparison']}
                        </h1>
                        <div class="product-comparison-divider"></div>

                        <div class="product-comparison-table-container">
                            ${this.productImages()}

                            ${this.comparisons['comparison_data']?.map((comparison) => {
                                return html`
                                    <table class="product-comparison-table">
                                        <tr class="product-comparison-table-head">
                                            <th colspan="${comparison['comparisons'][0]['products']?.length + 1}" class="product-comparison-table-title">
                                                ${comparison['comparison_section']}
                                                ${comparison['comparison_section_badge'] ? html`<span class="product-comparison-table-badge">${comparison['comparison_section_badge']}</span>` : nothing}
                                            </th>
                                        </tr>
                                        
                                        ${comparison['comparisons']?.map((productComparison) => {
                                            return html`
                                                <tr class="product-comparison-content-container">
                                                    <td class="product-comparison-content">
                                                        <p class="product-comparison-content-title">
                                                            ${productComparison['title']}    
                                                        </p>
                                                        <p class="product-comparison-content-subtitle">
                                                            ${unsafeHTML(productComparison['subtitle'])}
                                                        </p>
                                                    </td>
                                                    
                                                    ${this.productCell(productComparison)}
                                                </tr>
                                            `
                                        })}
                                    </table>
                                `})}
                        </div>
                    </div>
                </div>
            </div>

            <insurance-rider-modal isShown="${this.isRiderPlanShown}" .riderBenefits=${this.riderBenefits}></insurance-rider-modal>
        `;
    }
}

customElements.define('insurance-product-comparison', InsuranceProductComparison);