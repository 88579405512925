import { LitElement, html, css, unsafeCSS } from "lit";
import dropdown from "../../assets/images/dropdown.svg";
import { sharedStyles } from "../style/text-field.js";
import successIcon from "../../assets/images/success-icon.svg";
import closeIcon from "../../assets/images/close.png";
import { Aloproteksi } from '../lib/aloproteksi';

class ConsultFormCorporate extends LitElement {
  static get properties() {
    return {
      _isModalOpen: {
        type: Boolean,
        state: false,
      },
      company_name: { type: String },
      company_department: { type: String },
      company_count_employee: { type: String },
      employee_fullname: { type: String },
      employee_phone: { type: String },
      employee_email: { type: String },
      isFormValid: { type: Boolean },
      value: { type: String },
      options: { type: Array },
      showDropdown: { type: Boolean },
      showModal: {
        type: Boolean,
        state: true
      },
      errorEmail: { type: String },
      linkCalendly: {
        type: String
      },
      csrf: {
        type: Object,
        state: true,
        attribute: false
      },
      isSuccessfullySubmit: {
        type: Boolean,
        state: true,
        attribute: false
      },
      errorPhoneNumber:{
        type: String
      }
    };
  }

  constructor() {
    super();
    this._isModalOpen = false;
    this.company_name = "";
    this.company_department = "";
    this.company_count_employee = "";
    this.employee_fullname = "";
    this.employee_phone = "";
    this.employee_email = "";
    this.isFormValid = false;
    this.value = "";
    this.options = [
      "1 - 5 orang",
      "6 - 10 orang",
      "11 - 50 orang",
      "51 - 100 orang",
      "Lebih dari 100 orang",
    ];
    this.showDropdown = false;
    this.errorEmail = "Format email tidak sesuai. Contoh: email@mail.com";
    this.errorPhoneNumber = 'Nomor Ponsel harus valid, min. 10 digit dan maks. 13 digit';
    this.showModal = false;
    this.csrf = {};
  }

  static styles = [
    sharedStyles,
    css`
      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
      }

      *,
      ::before,
      ::after {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
        border-color: #ececec;
      }

      .button-container {
        display: flex;
        position: absolute;
        margin: -250px 0 0 130px;
      }

      .consultation-btn {
        border: 1px solid #ffffff;
        border-radius: 8px;
        padding: 12px 20.5px;
        width: fit-content;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-right: 12px;
        color: #ffffff;
        cursor: pointer;
      }

      .offer-btn {
        background: #ed6700;
        border-radius: 8px;
        padding: 12px 20.5px;
        width: fit-content;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        cursor: pointer;
      }

      input[type="text"] {
        width: 440px;
        padding: 15px;
        margin: 0 0 20px 0;
        display: inline-block;
        border: 1px solid;
        border-radius: 5px;
      }

      select {
        width: 474px;
        padding: 15px;
        margin: 0 0 20px 0;
        display: inline-block;
        border: 1px solid;
        border-radius: 5px;
        background: #ffffff;
      }

      // input [type=text]:focus {
      //     background-color: #ddd;
      //     outline: none;
      // }

      // label {
      //     background: #FFFFFF;
      //     position: absolute;
      //     top: 14px;
      //     left: 20px;
      //     transition: all 0.3s;
      //     pointer-events: none;
      //     font-size: 14px;
      //     color: #808080;
      // }

      // input[type="text"]:focus + label,
      // input[type="text"]:valid + label {
      //     transform: translateY(-125%);
      //     font-size: 0.8rem;
      //     color: rgb(39 39 39);
      // }

      // input[type="text"].readonly + label {
      //     transform: translateY(-125%);
      //     font-size: 0.8rem;
      //     color: rgb(39 39 39);
      // }

      .container-floating {
        position: relative;
        display: inline-block;
        color: #808080;
      }

      .nextBtn {
        background-color: #3570d2;
        color: white;
        padding: 16px 20px;
        margin: 8px 0;
        border-radius: 8px;
        cursor: pointer;
        width: 474px;
        opacity: 0.9;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
      }

      .nextBtn[disabled] {
        opacity: 0.4;
      }

      .my-dropdown {
        background-image: url(${unsafeCSS(dropdown)});
        background-repeat: no-repeat;
        background-position: 95% center;
      }

      .dropdown {
        position: relative;
        display: inline-block;
      }

      .dropdown input[type="text"] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
      }

      .dropdown ul {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 0;
        margin: 0;
        list-style: none;
        background-color: #fff;
        border: 1px solid #ccc;
      }

      .dropdown li {
        padding: 8px;
        cursor: pointer;
      }

      .dropdown li:hover {
        background-color: #f5f5f5;
      }

      .employee-count-dropdown {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 0px;

        position: absolute;
        width: 473px;
        top: 49.5%;

        /* Background Color/$color-background-white */

        background: #ffffff;
        /* $shadow-card */

        box-shadow: 0px 1px 10px rgba(66, 66, 66, 0.15);
        border-radius: 8px;
      }
      .employee-count-li {
        height: 30px;
        text-align: left;
        margin: 10px;
        content: "";
        width: 97%;
      }

      .employee-count-ul {
        padding-left: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        list-style: none;
        width: 97%;

      }

      li:before {
        content: ""; /* removes the default bullet */
      }

      .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        transition: all 0.2s;
        visibility: visible;
      }

      .modal-content {
        overflow-y: auto;
      }

      @media screen and (min-width: 768px) {
        .modal:before {
          display: inline-block;
          vertical-align: middle;
          content: " ";
          height: 100%;
        }
      }

      .modal[aria-hidden="true"] {
        /* display: none; */
        visibility: none;
        pointer-events: none;
        opacity: 0;
      }

      .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }

      hr {
        border: 1px solid #f1f1f1;
        margin-bottom: 30px;
        width: 100%;
      }

      .closeBtn {
        width: 13px;
        margin-left: 280px;
        cursor: pointer;
      }

      .container-corp {
        padding: 24px 16px 32px 10px;
      }

      .form-header {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 135%;
        margin-bottom: 16px;
      }

      .corp-con {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        background: linear-gradient(259.8deg, #ffffff 53.42%, #f4f8ff 95.52%);
        box-shadow: 0px 1px 10px rgb(66 66 66 / 15%);
        border-radius: 8px;
        width: 570px;
        background-color: #ffffff;
        position: relative;
        z-index: 999;
      }

      .success-modal {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 38px 46px 51px 46px;
        width: 420px;
        align-items: center;
        gap: 12px;
        transition: all 0.5s;
      }

      .modal-toolbar {
        align-self: flex-end;
        cursor: pointer;
      }

      .success-title {
        color: #424242;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
      }

      .success-description {
        color: #7f7f7f;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 20px;
      }

      .back-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3570d2;
        border-radius: 8px;
        padding: 12px 16px 12px 12px;
        color: #ffffff;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        gap: 8px;
        cursor: pointer;
        width: 320px;
        text-decoration: none;
      }
    `,
  ];

  redirectToSimulation() {
    const currentOrigin = window.location.origin;
    const simulationPath = "/simulations";
    const urlSimulation = `${currentOrigin}${simulationPath}`;
    window.location.href = urlSimulation;
  }

  connectedCallback() {
    super.connectedCallback();

    this.csrf = {
      ...this.csrf,
      param: document.querySelector('head > meta[name="csrf-param"]')?.content,
      token: document.querySelector('head > meta[name="csrf-token"]')?.content
    }
  }

  redirectToCalendly(event) {
    event.preventDefault();

    window.open(this.linkCalendly, '_blank');
    this.showModal = false;
    this.isSuccessfullySubmit = false;
    Aloproteksi.sendEventGA4({
      event: "set_schedule",
    });
  }

  trackGtm(field){
    Aloproteksi.sendEventGA4({
      event: "click_form_field",
      field: field
    });
  }
  
  renderSuccessModal() {
    return html`
      <div class="success-modal">
        <div class="success-icon">
          <img src="${successIcon}" alt="success checkmark" />
        </div>

        <p class="success-title">Formulir Berhasil Dikirim</p>
        <p class="success-description">
          Terima kasih sudah memilih Aloproteksi. Silahkan pilih waktu dan
          tanggal untuk melanjutkan proses.
        </p>
        <button @click=${this.redirectToCalendly} class="back-btn">
          Atur Jadwal via Calendly
        </button>
      </div>
    `
  }

  render() {
    return html`
      <div class="modal" aria-hidden="${!this.showModal}" role="dialog" id="modal_consult">
        ${!this.isSuccessfullySubmit ? html`
            <div class="modal-content">
                <div class="corp-con" id="plan-information-modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="container-corp">
                            <div class="form-header">
                              <span>Isi Formulir Konsultasi</span>
                              <img type="button" @click="${this.closeModal}" class="closeBtn" src="${closeIcon}">
                            </div>
                            <hr>
                            <div class="form-body">
                                <form id="consultation-form" class="form-horizontal" @submit="${this._onSubmit}">
                                    <div class="form-group">
                                      <div class="field">
                                        <input @click="${()=>this.trackGtm('fullname')}" id="company_name" class="floating__input"  type="" placeholder="Nama Perusahaan / Instansi" name="corporate_name" value=""  @input=${this.handleInputChange} autocomplete="off" />
                                        <label class="floating__label" data-content="Nama Perusahaan / Instansi"></label> 
                                      <div id="" class="error-messages">Nama Perusahaan / Instansi harap diisi</div>
                                    </div>
                                    <div class="form-group">
                                      <div class="field">
                                        <input @click="${()=>this.trackGtm('department')}" id="company_department" class="floating__input"  type="" placeholder="Departemen / Divisi" name="division" value="" @input=${this.handleInputChange} autocomplete="off" />
                                        <label class="floating__label" data-content="Departemen / Divisi"></label> 
                                      <div id="" class="error-messages">Departemen / Divisi harap diisi</div>
                                    </div>
                                    <div class="form-group">
                                      <div class="field">
                                        <input id="company_count_employee" class="floating__input my-dropdown"  type="" placeholder="Jumlah Karyawan Perusahaan" @click="${this.toggleDropdown}" .value="${this.company_count_employee}" name="number_of_employee" id="company_count_employee" autocomplete="off" readonly required />
                                        <label class="floating__label" data-content="Jumlah Karyawan Perusahaan"></label> 
                                      <div id="" class="error-messages">Jumlah Karyawan Perusahaan harap diisi</div>
                                    </div>
                                    ${
                                      this.showDropdown
                                        ? html`
                                            <div class="employee-count-dropdown">
                                                <ul class="employee-count-ul">
                                                    ${this.options.map(
                                                      (option) =>
                                                        html`
                                                          <div>
                                                            <li class="employee-count-li" @click="${this.selectOption}">
                                                              ${option}
                                                            </li>
                                                          </div>
                                                        `
                                                    )}
                                            </div>
                                            `
                                        : ""
                                    }
                                    </div>
                                    <div class="form-group">
                                        <div class="field">
                                          <input @click="${()=>this.trackGtm('fullname')}" id="employee_fullname" class="floating__input"  type="" placeholder="Nama Lengkap Pengisi" type="text" name="name" id="employee_fullname" @input=${this.handleInputChange} autocomplete="off" required />
                                          <label class="floating__label" data-content="Nama Lengkap Pengisi"></label> 
                                        <div id="" class="error-messages">Nama Lengkap Pengisi harap diisi</div>
                                    </div>
                                    <div class="form-group">
                                      <div class="field">
                                        <input @click="${()=>this.trackGtm('phone_number')}" id="employee_phone" class="floating__input" type="tel" placeholder="Nomor yang bisa dihubungi" name="phone_number" id="employee_phone" @input=${this.handleInputChange} autocomplete="off" required />
                                        <label class="floating__label" data-content="Nomor yang bisa dihubungi"></label>
                                      <div id="" class="error-messages">Nomor yang bisa dihubungi harap diisi</div>
                                    </div>
                                    <div class="form-group">
                                      <div class="field" >                       
                                        <input @click="${()=>this.trackGtm('email')}" id="employee_email" class="floating__input"  type="" placeholder="Alamat Email yang bisa dihubungi" name="email" id="employee_email" @input=${this.handleInputChange} autocomplete="off" required />
                                        <label class="floating__label" data-content="Alamat Email yang bisa dihubungi"></label>
                                      <div id="error_message" class="error-messages">Alamat Email yang bisa dihubungi harap diisi</div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                              <button form="consultation-form" type="submit" class="nextBtn" id="nextBtn" ?disabled="${!this.isFormValid}">Kirim Formulir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ` : this.renderSuccessModal()}
      </div>
    `;
  }

  async _onSubmit(event) {
    event.preventDefault();
    let userPostBody = {};
    let name, Eflag, valueInput, errorMessage, errorEl;
    const form = this.shadowRoot.querySelectorAll(".floating__input");

    for (let i = 0; i < form.length; i++) {
      name = form[i].name
      if (name == 'email'){
        valueInput = form[i].value.replace(/\s/g, "")
      }else{
        valueInput = form[i].value
      }
      userPostBody[name] = valueInput
      errorEl = form[i].parentElement.lastElementChild
    
      if (valueInput) {
        if (name == 'email') {
          Eflag = !this.validateEmail(valueInput)
          errorMessage = Eflag ? this.errorEmail : ''
          errorEl.innerHTML = errorMessage
        } else if(name == 'phone_number'){
          Eflag = !this.validatePhone(valueInput)
          errorMessage = Eflag ? this.errorPhoneNumber : ''
          errorEl.innerHTML = errorMessage
        }
      }

      if (Eflag) {
        form[i].style.border = '1px solid #D0021B'
        errorEl.style.display = 'block'
      } else {
        form[i].style.border = '1px solid #7F7F7F'
        errorEl.style.display = 'none'
      }
    }

    // do something with the data
    const inputElement = this.shadowRoot.querySelector(
      "#company_count_employee"
    );
    inputElement.readOnly = false;
    inputElement.classList.remove("readonly");
    const nextBtn = this.shadowRoot.querySelector("#nextBtn");
    nextBtn.disabled = true;
    // form.reset();

    if (!Eflag) {
      const res = await fetch("send-consult-form", {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.csrf['token'],
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userPostBody)
      });
  
      const resData = await res.json();
  
      if (resData?.status === 'success') {
        this.isSuccessfullySubmit = true

        Aloproteksi.sendEventGA4({
          event: "submit_consult",
        });
      }
    }
  }

  handleInputChange(event) {
    const inputField = event.target;
    if (inputField && inputField.type == "tel") {
      inputField.value = inputField.value.replace(/[^0-9]/g, "");
    }
    const propertyName = inputField.id;
    const propertyValue = inputField.value;
    this[propertyName] = propertyValue;

    this.isFormValid = this.isAllFieldsFilled();
    if (this.isFormValid == true) {
      const nextBtn = this.shadowRoot.querySelector("#nextBtn");
      nextBtn.disabled = false;
    }
  }

  isAllFieldsFilled() {
    return (
      !!this.company_name &&
      !!this.company_department &&
      !!this.employee_fullname &&
      !!this.employee_phone &&
      !!this.employee_email
    );
  }

  closeModal() {
    document.querySelector("landing-banner").showModal = false;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.trackGtm('employee')
  }

  selectOption(e) {
    this.value = e.currentTarget.innerText;
    const inputElement = this.shadowRoot.querySelector(
      "#company_count_employee"
    );
    inputElement.readOnly = true;
    inputElement.classList.add("readonly");
    inputElement.value = this.value;
    this.showDropdown = false;
  }

  validateEmail(email) {
    var emailTrim = email.replace(/\s/g, "")
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailTrim).toLowerCase());
  }

  validatePhone(phoneNumber){
    var re =/^[0-9]{10,13}$/;
    return re.test(String(phoneNumber));
  }
}

customElements.define("consult-form-corporate", ConsultFormCorporate);
