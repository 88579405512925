import { LitElement, html, css } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceFaq extends LitElement {
    static get properties() {
        return {
            insuranceFaq:{
                type: Object, 
                attribute: "insurance-faq"
            }
        };
    }

    constructor() {
        super();
    }

    static styles = css`
        .section-faq {
            margin-top: 88px;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(229, 239, 255, 0.37) 100%);
        }
        .container-faq-main {
            width: 1000px;
            padding: 88px 0 0 0;
            margin: 0 auto;
        }
        .faq-container {
            /* margin-bottom: 129px; */
        }
        .title{
            margin: 0 auto;
            margin-bottom:48px;
            font-size: 32px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.72;
            letter-spacing: normal;
            text-align: center;
            color: #3b3738;
        }
        .sub-title{
            height: 75px;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #424242;
            padding-left: 25px;
            align-items: center;
            text-align: center;
            display: flex;
            border-left: 4px solid #f0f0f0;
            cursor: pointer;
            color: #cacaca;
        }
        .contents {
            margin-top: 5%;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #424242;
        }
        .faq-container-inner {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: #424242;
        }
        .content-container-faq {
            position: absolute;
            left: 315px;
            top: 0px;
        }
        .content-faq-container {
            margin-left:120px;
        }
        .content-faq {
            position: relative;
            height: 800px;
        }
        .hidden {
            display: none;
        }
        .activeTab {
            border-left: 4px solid #3570d2;
            color: #424242;
        }

        .container-inner{
            display: flex;
            flex-direction: row;
            position: relative;
            margin-top:30px;
        }
    
        .title-faq-list{
            padding-top:15%;
            margin: 0 auto;
            font-size: 32px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.72;
            letter-spacing: normal;
            text-align: center;
            color: #3b3738;
        
        }
    
        .sub-sub-title{
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            color: #424242;
        }
    
        .active {
            color: #3570d2;
        }
    
        .btn-show-hide {    height: 100%;
            content:"";
        }
    
        .contents {
            margin-top: 5%;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: #424242;
        }
    
        .hidden {
            display: none;
        }
    
        .right {
            width: 676px;;
        }
    
        .title-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor:pointer;
        }

        .content {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            color: #424242;
        }
    
        .plus-button {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: linear-gradient(#9b9b9b,#9b9b9b), linear-gradient(#9b9b9b,#9b9b9b);
            background-position: center;
            background-size: 50% 2px,2px 50%;
            background-repeat: no-repeat;
        }

        .minus-button {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: linear-gradient(#3570d2,#3570d2);
            background-position: center;
            background-size: 50% 2px,2px 50%;
            background-repeat: no-repeat;
        }
    `;

    render() {
        return html`
        <div class="section-faq">
            <div class="container-faq-main">
                <div class="faq-container">
                    <div class="title">
                        ${this.insuranceFaq[0].title}
                    </div>
                    <div id="contentFAQ" class="content-faq">
                        ${repeat(
                            this.insuranceFaq,
                            (item) => html`
                            <div class="faq-container-inner">
                                <div class="sub-title" @click="${(e)=>this._showElement(e, item.group_title)}">${item.group_title}</div>
                                <div class="content-container-faq hidden">
                                    <div class="content-faq-container">
                                        <div class="container-list">
                                            <div class="faq-container">
                                                <div id="contentFAQInner" class="content-faq">
                                                    ${repeat(
                                                        item.items,
                                                        (subitem) => html`
                                                        <div class="container-inner">
                                                            <div class="right" on-click="_click-${subitem.order}">
                                                                <div class="title-container" @click="${this._showContent}">
                                                                    <div class="sub-sub-title">${subitem.title}</div>
                                                                    <div class="btn-show-hide plus-button"></div>
                                                                </div>
                                                                    <div class="content hidden">
                                                                    <p .innerHTML='${subitem.description}'></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>`
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>`
                        )}
                    </div>
                </div>
            </div>
        </div>
        `;
    }

    ready() {
        super.ready();
    }

    connectedCallback(){
        super.connectedCallback();
        this.firstUpdated();
    }

    firstUpdated() {
        let contentFAQ = this.renderRoot.querySelectorAll('.sub-title');
        let detailContentFAQ = this.renderRoot.querySelectorAll('.content-container-faq');
        if (contentFAQ.length > 0 && detailContentFAQ.length > 0) {
            contentFAQ[0].classList.add("activeTab")
            detailContentFAQ[0].classList.remove("hidden");
        }
    }

    _showElement(e, group_title){
        let currActiveTab = document.querySelector("insurance-faq").renderRoot.querySelector(".activeTab");
        
        if(currActiveTab != null){
            currActiveTab.classList.remove("activeTab");
            currActiveTab.nextElementSibling.classList.add("hidden")
        }
        
        e.srcElement.classList.add("activeTab");
        e.srcElement.nextElementSibling.classList.remove("hidden")
        Aloproteksi.sendEventGA4({
            event: "click_faq_landing",
            section: group_title
        });
    }

    _showContent(e){
        let minEl = this.renderRoot.querySelector(".minus-button");
        if(minEl != null){
          minEl.classList.remove("minus-button")
          minEl.classList.add("plus-button")
          minEl.parentElement.parentElement.querySelector(".content").classList.add("hidden")
        }
        var btn = e.srcElement.parentElement.querySelector(".btn-show-hide");
        if(btn.classList.contains("plus-button")) {
          btn.classList.remove("plus-button");
          btn.classList.add("minus-button");
          btn.parentElement.parentElement.querySelector(".content").classList.remove("hidden");
        }else{
          btn.classList.add("plus-button");
          btn.classList.remove("minus-button");
          btn.parentElement.parentElement.querySelector(".content").classList.add("hidden");
        }
      }

}

customElements.define('insurance-faq', InsuranceFaq);


