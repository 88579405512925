import { LitElement, html, css } from 'lit';
import logoProteksi from '../../assets/images/aloproteksi.png'

class TopNavbar extends LitElement {
    static get properties() {
        return {
            corpDashboardBaseUrl:{
                type: String,
                attribute: 'corp-dashboard-base-url'
            }
        }
    }

    static styles = css`
    .proteksi-logo{
        height: 14px;
        margin-left: 5%;
    }
    .container-bar{
        height: 64px;
        left: 0px;
        top: 0px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(66, 66, 66, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .right-cont{
        display: flex;
        height: fit-content;
    }
    .button-cont{
        display: flex;
    }
    .visit-text{
        margin-right: 98px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .log-in-btn{
        padding: 12px 15px;
        border: 1px solid #3570D2;
        border-radius: 5px;
        margin-right: 16px;
        cursor: pointer;
    }
    .download-btn{
        padding: 12px 15px;
        background: #3570D2;
        color: #ffffff;
        border-radius: 5px;
        margin-right: 81px;
        cursor: pointer;
    }
    `;
    render() {
        return html`
            <div class="container-bar">
                <img class="proteksi-logo" src="${logoProteksi}"/>
                <div class="right-cont">
                    <div class="visit-text" @click="${this.openAloWeb}">Kunjungi Alodokter</div>
                    <div class="button-cont">
                        <div class="log-in-btn" @click="${this.openLogin}">Masuk</div>
                        <div class="download-btn" @click="${this.downloadApp}">Download Aplikasi</div>
                    </div>
                </div>
            </div>
        `;
    }

    openLogin(event){
        event.preventDefault();
        window.location.href = `${this.corpDashboardBaseUrl}/login`    
    }

    downloadApp(){
        window.open('http://www.alodokter.com/konsultasi-dokter', '_blank');
    }

    openAloWeb(){
        window.open('http://www.alodokter.com', '_blank');
    }
}

customElements.define('top-navbar', TopNavbar);