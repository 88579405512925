import { LitElement, html, css } from "lit";

class InsuranceSection extends LitElement {
    static get properties() {
        return {

        }
    }

    static styles = css`
        .table-container{
            justify-content: center;
            display: flex;
        }
        .table-price-group {
            width: 930px;
            height: 517px;
            border-spacing: 0px;
            border-bottom: 1px solid #92B9FA;
        }
        .feature-image {
            margin-bottom: 36px;
        }
        .row-1 {
            width: 186px;
            cellspading: 24px 32px;
            border: 1px solid #92B9FA;
            text-align: center;
        }
    `;
    render() {
        return html `
        <div id="priceFormPopup" class="price-group-container">
            <div class="price-group-modal">
                
                <hr>
                <div class="table-container">
                    <table class="table-price-group">
                        <thead>
                            <tr>
                                <th></th>
                                <th><img class="feature-image" src="http://res.cloudinary.com/dk0z4ums3/image/upload/v1665047937/insurance_templates/product_benefit_image_MCQ.png"></th>
                                <th><img class="feature-image" src="http://res.cloudinary.com/dk0z4ums3/image/upload/v1665047937/insurance_templates/product_benefit_image_MCQ.png"></th>
                                <th><img class="feature-image" src="http://res.cloudinary.com/dk0z4ums3/image/upload/v1665047937/insurance_templates/product_benefit_image_MCQ.png"></th>
                                <th><img class="feature-image" src="http://res.cloudinary.com/dk0z4ums3/image/upload/v1665047937/insurance_templates/product_benefit_image_MCQ.png"></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                                <tr>
                                    <td class="row-1">0 - 17 tahun</td>
                                    <td class="row-1">Rp 1.260.000</td>
                                    <td class="row-1">Rp 2.520.000</td>
                                    <td class="row-1">Rp  5.040.000</td>
                                    <td class="row-1">Rp 7.560.000</td>
                                </tr>
                                <tr>
                                    <td class="row-1">0 - 17 tahun</td>
                                    <td class="row-1">Rp 1.260.000</td>
                                    <td class="row-1">Rp 2.520.000</td>
                                    <td class="row-1">Rp  5.040.000</td>
                                    <td class="row-1">Rp 7.560.000</td>
                                </tr>
                                <tr>
                                    <td class="row-1">0 - 17 tahun</td>
                                    <td class="row-1">Rp 1.260.000</td>
                                    <td class="row-1">Rp 2.520.000</td>
                                    <td class="row-1">Rp  5.040.000</td>
                                    <td class="row-1">Rp 7.560.000</td>
                                </tr>
                                <tr>
                                    <td class="row-1">0 - 17 tahun</td>
                                    <td class="row-1">Rp 1.260.000</td>
                                    <td class="row-1">Rp 2.520.000</td>
                                    <td class="row-1">Rp  5.040.000</td>
                                    <td class="row-1">Rp 7.560.000</td>
                                </tr>
                                <tr>
                                    <td class="row-1">0 - 17 tahun</td>
                                    <td class="row-1">Rp 1.260.000</td>
                                    <td class="row-1">Rp 2.520.000</td>
                                    <td class="row-1">Rp  5.040.000</td>
                                    <td class="row-1">Rp 7.560.000</td>
                                </tr>
                                
                            
                        </tbody>
                    </table>
                </div>
                <hr style=margin-top:32px;width:726px;>
            </div>
        </div>            
                
        `; 
    }
}

customElements.define('insurance-section', InsuranceSection);