import { LitElement, html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import circleDot from '../../assets/images/circle-dot.png'
import { when } from 'lit/directives/when.js';
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceProductCoverage extends LitElement {
    static get properties () {
      return {
        listCoverage: {
            type: Object,
            attribute: 'list-coverage'
          },
        videoLink: {
          type: String,
          attribute: 'video-link'
        },
        player:{
          type: Object
        }
      }
    };

    constructor() {
        super();         
    }

    static styles = css`
        .main-container-product-coverage {
          background: linear-gradient(259.8deg, #FFFFFF 53.42%, #F4F8FF 95.52%);
          margin: -50px auto;
          box-shadow: 0px 1px 10px rgba(66, 66, 66, 0.15);
          border-radius: 12px;
          position: relative;
          z-index: 1;
          width: 1440px;
        }

        .cont{
          background-repeat: no-repeat;
          background-position: left 38%;
        }
        .sub-container{
          display: flex;
          padding: 76px;
        }
        .right-section{
          width: 100%;
          text-align: center;
          justify-content: center;
          margin-left: 10px;
          height: fit-content;
        }
        .left-section{
          font-weight: 700;
          font-size: 24px;
          line-height: 42px;
          width: 300px;
        }
        .limit-tag-blue{
          background: linear-gradient(270deg, #6AC0FF -25.57%, #0F7AD3 35.53%, #0270CD 63.98%, #6AC0FF 121.49%);
          border-radius: 30px;   
          font-weight: 700;
          font-size: 18px;
          line-height: 24px; color: #FFFFFF;
          padding: 11px 90px;     
          width: 60%;
          margin: 0 auto;
          margin-bottom: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .coverage-icon{
          width: 20px;
          margin-right: 12px;
        }
        
        .list-container{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding-left: 43px
        }
        .icon-item{
          width: 56px;
          height: 56px;
        }
        .list-item{
          display:flex;
          flex-basis: 50%;
          margin-bottom: 25px;
          justify-content: center;
        }
        .text-item{
          text-align: left;
          margin-left: 16px;
          justify-content: center;
          width: 50%
        }
        .text-title-coverage{
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #424242;
          margin-bottom: 5px;
          width: max-content;
        }
        .text-description{
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #7F7F7F;
        }

        .video-section{
          display: flex;
          justify-content: center;
          /* margin-bottom: 70px; */
          padding-bottom: 70px;
        }
        .video-title{
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #424242;
          text-align: center;
          margin-bottom: 32px;
        }
        .admin-tag{
          background: linear-gradient(270deg, #6AC0FF 0%, #1483E2 100%);
          border-radius: 25px;
          color: #FFFFFF;
          font-weight: 400;
          font-size: 12px;
          padding: 2px 12px;
        }

        .separator {
          border: 1px solid #ECECEC;
          opacity: 0.8;
          width: 80%;
          margin-bottom: 48px
        }
        #video{
          border-radius: 8px;
        }

        @media screen and (max-width: 1440px) {
          .limit-tag-blue {
            width: 70%;
            margin: 0px 0px 50px 65px;
          }
          .text-item{
            width: 70%;
          }
        }
    `;

    trackGtm(){
      Aloproteksi.sendEventGA4({
        event: "play_video_aloproteksi",
      });
    }

    connectedCallback() {
      super.connectedCallback();
      this.loadYouTubeAPI();
    }

    loadYouTubeAPI() {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => this.initializePlayer();
      } else {
        this.initializePlayer();
      }
    }
    onPlayerStateChange(event){
      const playerState = event.data;
      switch (playerState) {
        case window.YT.PlayerState.PLAYING:
          // Video is playing
          this.trackGtm()
          break;
        default:
          break;
      }
    }
  
    initializePlayer() {
      this.player = new YT.Player(this.shadowRoot.querySelector('#video'), {
        height: '460',
        width: '800',
        videoId: this.getVideoIdFromLink(this.videoLink),
        events: {
          onStateChange: this.onPlayerStateChange.bind(this),
        },
      });
    }
  
    getVideoIdFromLink(link) {
      const videoId = link.split('/').pop();
      return videoId;
    }
    
    render() {
        return html`
            <div>
              <div class="main-container-product-coverage" >
                  <div class="cont" style="background-image: url('${circleDot}')">
                    <div class="sub-container">
                        <div class="left-section">Perlindungan Kesehatan Karyawan Secara Menyeluruh</div>
                        <div class="right-section">
                          <div class="limit-tag-blue">
                              <img class="coverage-icon" src="${this.listCoverage.icon}">
                              <div class="title-tag">${this.listCoverage.title}</div>
                          </div>
                          <div class="list-container">
                              ${repeat(
                              this.listCoverage.list_coverage,
                              (listCoverage) => html`
                              <div class="list-item">
                                <img class="icon-item" src="${listCoverage.icon}"/>
                                <div class="text-item">
                                    <div class="text-title-coverage">
                                      ${listCoverage.title} 
                                      ${when(listCoverage.admin_tag, () => html`<span class="admin-tag" >${listCoverage.admin_tag}</span>`, () => nothing)}
                                    </div>
                                    <div class="text-description">${listCoverage.subtitle}</div>
                                </div>
                              </div>
                              `
                              )}    
                          </div>
                        </div>
                    </div>
                    <hr class="separator">
                    <div class="video-title">Simak Cara Penggunaan Aloproteksi Rawat Jalan & Rawat Inap</div>
                    <div class="video-section">
                      <div id="video" class="video-container"></div>
                    </div>
                  </div>
              </div>
            </div>
        `;
    }
}

customElements.define('insurance-product-coverage', InsuranceProductCoverage);


