import { LitElement, html, css } from 'lit';
import ovalLeft from '../../assets/images/oval-left.png'
class InsuranceMonitoringView extends LitElement {
    static get properties() {
        return {
            monitoringView:{
                type:Object, 
                attribute: 'monitoring-view'
            }
        }
    }
    constructor() {
        super();        
    }

    static styles = css`
        .main-container{
            background: rgba(229, 239, 255, 0.4);
        }
        .monitoring-container{
            margin: 0 auto;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) -26.62%, rgba(243, 247, 255, 0.39) 100%);
            text-align: center;
            padding-top: 70px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left -138px;
            background-origin: content-box;
        }
        .monitoring-title{
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            color: #424242;
            margin-bottom: 16px;
        }
        .monitoring-subtitle{
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #7F7F7F;
            width: 40%;
            margin: 0 auto;
        }
        .monitoring-image{
            margin-top: 49px;
            width: 928px;
            height: 522px;
        }
    `;

    render() {
        return html`
            <div class="main-container">
                <div class="monitoring-container" style="background-image: url('${ovalLeft}')">
                    <div class="monitoring-title">${this.monitoringView.title}</div>
                    <div class="monitoring-subtitle" .innerHTML="${this.monitoringView.subtitle}"></div>
                    <img class="monitoring-image" src="${this.monitoringView.monitoring_image}">
                </div>
            </div>
            
        `;
    }

}

customElements.define('insurance-monitoring-view', InsuranceMonitoringView);


