import {css} from 'lit';
export const sharedStyles = css`
    :root {
        --field__background--hover: #e9e9e9;
        --input__border: #7F7F7F;
        --label__color: #424242;
        --font__size: 16px;
    }
    
    .floating {
        transition: background-color 0.2s ease;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    
    .floating__input {
        padding: 12px 16px;
        border: 1px solid #7F7F7F;
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
        width: -webkit-fill-available;
        color: #424242;
    }
    
    .floating:hover .floating__input {
        border-color: #7F7F7F;
    }
    
    .floating__input::placeholder {
        color: rgba(0, 0, 0, 0);
    }
    
    .floating__label, .floating__label_month{
        display: block;
        position: relative;
        max-height: 0;
        font-weight: 500;
        font-size: 16px;
        pointer-events: none;
        color: #424242;
    }
    
    .floating__label::before{
        color: #7F7F7F;
        content: attr(data-content);
        display: inline-block;
        filter: blur(0);
        backface-visibility: hidden;
        transform-origin: left top;
        transition: transform 0.2s ease;
        left: 1rem;
        position: relative;
    }
    
    .floating__label::after, .select-before::after {
        bottom: 1rem;
        content: "";
        height: 0.1rem;
        position: absolute;
        transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
        opacity: 0;
        left: 0;
        top: 100%;
        margin-top: -0.1rem;
        transform: scale3d(0, 1, 1);
        width: 100%;
        background-color: var(--color__accent);
    }
    
    .floating__input:focus + .floating__label::after {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
    
    .floating__input:placeholder-shown + .floating__label::before {
        transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
    }
    
    .floating__label::before,
    .floating__input:focus + .floating__label::before {
        transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
    }
    
    .floating__input:focus + .floating__label::before {
        color: var(--color__accent);
    }
    
    .floating__input:focus , .floating__input:not(:placeholder-shown) {
        padding-top: 35px !important;
        height: 70px;
    }

    .text-area{
        top: -45px;
    }
    
    .textarea:focus, .textarea:not(:placeholder-shown) {
        height: 90px;
    }
            
    .text-area::before, .floating__input:focus + .text-area::before {
        transform: translate3d(0, -2.12rem, 0) scale3d(0.82, 0.82, 1);
    }
    
    .input-month:focus , .input-month:not(:placeholder-shown), .input-month > option[value="Bulan"]{
        height: 50px;
    }
    
    .input-month > option[value="Bulan"]  + .floating__label::before{
        visibility: hidden;
    }
    
    .input-month > option[value=""] + .floating__label::before {
        transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
    }
    
    .floating__label_month::before{
        content: "";
        display: none;
    }
    
    .select-before::before{
        transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
        color: var(--label__color);
        content: attr(data-content);
        display: inline-block;
        filter: blur(0);
        backface-visibility: hidden;
        transform-origin: left top;
        transition: transform 0.2s ease;
        left: 15px;
        position: relative;
        font-size: 16px;    
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    
    .hidden--visually {
        border: 0;
        clip: rect(1px 1px 1px 1px);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    .field {
        margin-bottom: 14px;
        color: #424242;
        font-size: 14px;
        line-height: 18px;
    }
    
    
    .floating-label { 
        position:relative; 
        margin-bottom:20px; 
    }
    
    .floating-select {
        display:block;
        width:100%;
        height:30px;
        background-color: transparent;
        border:none;
    }
        
    .floating-select:focus , .floating-select:not([value=""]) {
        padding-top: 35px !important;
        height:70px;
        outline:none;
    }
                
    .label-select {
        font-weight: 500;
        font-size: 16px;
        position:absolute;
        pointer-events:none;
        left: 18px;
        top: 16px;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
    }
    
    .label-select-disabled {
        font-weight: 500;
        font-size: 16px;
        position:absolute;
        pointer-events:none;
        left: 18px;
        top: 16px;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
        color: grey;
    }
                
                
    .floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
        font-size:14px;
        height:70px;
        outline:none;
    }
                
    /* active state */
    .floating-select:focus ~ .floating-select:focus ~  {
        width:50%;
    }
                
    *, *:before, *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
                
    /* active state */
    .floating-select:focus ~  {
        -webkit-animation:inputHighlighter 0.3s ease;
        -moz-animation:inputHighlighter 0.3s ease;
        animation:inputHighlighter 0.3s ease;
    }
            
    .error-messages {
        color: #D0021B;
        font-size: 12px;
        line-height: 18px;
        margin-top: 4px;
        display: none;
    }

    .floating__input__error {
        border-color: #D0021B;
    }

    .floating__input__error:focus + .floating__label::before {
        color: #D0021B;
    }
  `;


