import { LitElement, html, css, unsafeCSS } from 'lit';
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceComparationCard extends LitElement {
    static get properties() {
      return {
        comparationCard: {
            type: Object,
            attribute: 'comparation-card'
        },
        isPricingSection: {
          type: String,
          default: "false",
          state: true
        },
        priceGroups: {
          type: Array,
          attribute: 'price-groups'
        },
        contactSupport: {
          type: Object,
          attribute: 'contact-support'
        }
      }
    }

    constructor() {
        super();
    }

    static styles = css`
      .main_container_comparation {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        margin-top:120px;
        gap: 40px;
        width: 100%;
        height: auto;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        z-index: 1;
      }
      
      .title_comparation {
        width: 486px;
        height: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #424242;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      
      .container_card {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 100%;
        height: auto;
        flex: none;
        order: 1;
        flex-grow: 0;
        justify-content: center;
      }
      
      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px 26px;
        gap: 40px;
        isolation: isolate;
        height: auto;
        border: 1px solid #ECECEC;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
      
      .logo_image {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 13px;
        width: 242px;
        height: 23px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        z-index: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      
      .product-image {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 13px;
        width: 242px;
        height: 23px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        z-index: 0;
        background-position: center;
        background-repeat: no-repeat;
      }
      
      .oval_copy_top {
        position: absolute;
        border-radius: 8px;
        width: 130.24px;
        height: 148.98px;
        top: 0;
        left: 0;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABlCAYAAADqKnWSAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM8SURBVHgB7dy9btpQGMbx4zSK1CLUZqBSJKaOMPYGehMwt0uYewXQy2gvoVxEs2VihLFSpUpIVGpUoYryZdeP4SDH8ccL2GD7PL8hoWH76z2vbRrFUm8/v1AUbtCZuV8dvLxQFK3V2vVhqDjD5jP9kqHivFacKJHpDfpYeMlQibxdzlCJ3n3y9hRDJZlsGjFUoob3laGSvPzJoyeyqPCqJ+dYDCXRal8wlMSvJidKiqEkpjecKCmGkpiNOVEyDU6UFEMJMZQQQ0k8f3AYSoihJKpjTpQUQ0nUhpwoKYaS6PdthpLh0UtUHdv4xlBCDJVkojhRIs3hGt8YKkn/KydKxuKvJibaXvGAoeJsFzkwVJztIgeGiuM+uuiXDBVls58c/U+GiuLbT8BQUXz7CRgqTPXKdvcTQyWaVOzgjxgqTPPxNAFDBYUcO2CooJBjBwwVNGotw37MUH7eTablhL3FUH61xjLqLYbSvCXeXke9zVBaxBLXGEqLWOIaQ8HsehW1xDWGgoRpAoYSTBMwlGCawOxQwmkCc0Phvkk4TWBuqOn9WjpNYGYoTNPgi3iawMxQtcpekcC8UFjgMc90UcwKtecC9zMrlHfk5Avcz5xQBx45zYxQRxw5zYxQRxw5zYBQg+UxR04rd6gDbiyjlDcUIt29n6uUlDPU1V9nE+m4veRXzlD1+iLNSFDCUOks76CShXIjpbS8g0oUKrtIcKnKYDZeqVF2kaD4E+VF6i1Uxgo+Ue5xy3iStAJPVLY7KaigoU4bCYoVCnfcb67np44ExdlR3rPb7TztO26pYkzU7gH3PJEg/6Fw+b/78O+ckSC/Rw9ThE8m+53Un9sOkc9Q+I+AQWt57inyy9fR01e1UXuRp0iQn4nCLhp0czVFfuefKOwib4p6uZsiv/NNFI4ZPonM4EO2LJw+FAItKit1/3GV5wkKOl2oJ4E6qkiyD7W7H7q1ixhIyyYUpudPfa2aal2UHZQk3VCYHvXDfeTobn4/cqRK47hQmJxXl7aa/V7v4nh6qmz2C+UPU2s4j5/DeqrMwkMhCNTdPfP9wcGfgFXf3ImxinM5T9ulG2HhTUdz5Khu14mMYfWUyf4DRxosHr39Gd4AAAAASUVORK5CYII=');
        background-repeat: no-repeat;
        flex: none;
        order: 0;
        flex-grow: 0;
        z-index: 2;
      }
      
      .oval_copy_bottom {
        position: absolute;
        border-bottom-right-radius: 8px;
        width: 66px;
        height: 64px;
        bottom: 0px;
        right: 0px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjUiIHZpZXdCb3g9IjAgMCA2NiA2NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2Ugb3BhY2l0eT0iMC4wNCIgY3g9IjczIiBjeT0iNjcuNjQyMiIgcng9IjY1LjEyMTIiIHJ5PSI3NC40OTEyIiB0cmFuc2Zvcm09InJvdGF0ZSg2MCA3MyA2Ny42NDIyKSIgZmlsbD0iIzAwM0E5RSIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        flex: none;
        order: 0;
        flex-grow: 0;
        z-index: 2;
      }
      
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 242px;
        height: auto;
        flex: none;
        order: 1;
        flex-grow: 0;
        z-index: 1;
      }
      
      .frame {
      }
      
      .card_text {
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #424242;
      }
      
      .price_container {
        margin-bottom: 10px;
        padding: 0px;
        width: 100%;
        height: auto;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
      
      .price_text {
        width: 100%;
        height: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #424242;
      }
      
      .price_month {
        width: 100%;
        height: auto;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: #424242;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
      
      .usp {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        height: auto;
      }
      
      .usp_check {
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
      
      .usp_check_image {
        width: 20px;
        height: 20px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgBjZBPDsFQEMa/KZHYSBs2dnWDukEdwQnYoFbqBNITYIVV3YCbOEK7k6BhJ5HUM9OoVCrRSV7e/Hm/N98MUNCODsxohIn45aJQRWHHl8WgpRUBIgczAQgINYJH/4DTAP2SBl98BlrGmkEJzkO4UsSPOUqEeRIoTAUQl6TAeoP3u+2D2ze5GLjQa3ccOGfyWdY3cNPPEnnRmBMx6yboHIbxEx5LsYnQkzmoiraxwO0LErtyR6XgK8DOFEMGO6msHPTZVKar0tBtrLBHEZOuFye/mNReDss9p74cmrwAAAAASUVORK5CYII=');
        background-position: center;
        background-repeat: no-repeat;
      }
      
      .usp_text {
        width: 100%;
        height: auto;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: #424242;
      }
      
      .box_button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
        gap: 20px;
        width: 294px;
        height: 56px;
        background: #FAFAFA;
        border: 1px solid #ECECEC;
        border-radius: 8px;
        flex: none;
        order: 2;
        flex-grow: 0;
        justify-content: center;
        cursor: pointer;
      }
      
      .box_text {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #424242;
      }
      
      .arrow_box {
        width: 24px;
        height: 24px;
        flex: none;
        order: 1;
        flex-grow: 0;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD1SURBVHgB7dS/DYJAFAbwd7iATABs4AhIRekGYucW4iQyhlZgR6dOwNlZygLg9xJiiDHcn1AYw9fcy+UePy7kQTTl1zJTHQjDcO37vpBSPsgygoaBheM4F5TPpmmWRVFcySKDN+G3D4LAZU8IsfU8D1vyRmMinKqqjoBEB60A1YBKMogS6aCiB8WA+JZn0owW8gUKTSBtpAfVKGMTyAjpoBIPv/P3YQjoHHunoR6H7NK+i7Z1VYeNER5OzE7GNWZnn+d5ouoxQqIo2vUBDGeq0ydIMwxgSU0BbeQD2ADIyCBKBMABS2ILKJGxfpDKAEoYoyl/lReA7HmoBZ+R5gAAAABJRU5ErkJggg==');
      }
    `;

    openPricing(event) {
      this.isPricingSection = !this.isPricingSection;
      Aloproteksi.sendEventGA4({
        event: "view_product_price",
      });
    }

    render() {
        const sortedComparationCard = this.comparationCard.slice().sort((a, b) => a.position - b.position);
        return html`
          <div class="main_container_comparation">
            <div class="title_comparation">
                Paket Yang Tersedia Per Karyawan
            </div>
            <div class="container_card">
                ${sortedComparationCard.map((card, index) => html`
                    <div class="card" style="background-image:url(${card.background_url}); background-repeat: round;">
                        <img class="product-image" src="${card.product_icon}">
                        <div class="content">
                            <div class="frame">
                                <div class="card_text">
                                    Mulai dari
                                </div>
                                <div class="price_container">
                                    <span class="price_text">
                                        ${card.price}
                                    </span>
                                    <span class="price_month">
                                        / ${card.price_type}
                                    </span>
                                </div>
                            </div>
                           
                            ${card.benefits.map((benefit) => html`
                                <div class="usp">
                                    <div class="us_check">
                                        <div class="usp_check_image"></div>
                                    </div>
                                    <div class="usp_text">
                                      <div .innerHTML=' ${benefit}'></div>
                                    </div>
                                </div>
                            `)}
                        </div>
                    </div>
                `)}
            </div>
            <button class="box_button" @click="${this.openPricing}">
              <p class="box_text">Detail harga produk</p>
              <div class="arrow_box"></div>
            </button>
          </div>

          <insurance-pricing-section isShown="${this.isPricingSection}" .priceGroups=${this.priceGroups} .contactSupport=${this.contactSupport}></insurance-pricing-section>
        `;
    }

}

customElements.define('insurance-comparation-card', InsuranceComparationCard);


