import { LitElement, html, css } from 'lit';
import { Aloproteksi } from '../lib/aloproteksi';

class LandingBannerSecondary extends LitElement {
  static get properties() {
      return {
        bannerSecondary: {
          type: String,
          attribute: 'banner-secondary'
        }
      }
  }

  constructor(){
    super();
  }

  static styles = css`
      .banner-secondary{
          display: flex;
          justify-content: center;
      }
      .banner-img-secondary{
          width: 1240px;
      }
      .button-container-secondary{
          display: flex;
          position: absolute;
          margin: -95px 0 0 439px;
      }
      .offer-btn{
          background: #ED6700;
          border-radius: 8px;
          padding: 12px 20.5px;
          width: fit-content;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #FFFFFF;
          cursor: pointer;
          border: 0;
      }

      @media screen and (max-width: 1440px){
        .button-container-secondary{
            margin: -95px 0px 0px 200px;
        }
      }
  `;

  redirectToSimulation() {
    const currentOrigin = window.location.origin;
    const simulationPath = '/simulations';
    const urlSimulation = `${currentOrigin}${simulationPath}`;
    window.location.href = urlSimulation;
    Aloproteksi.sendEventGA4({
      event: "quotation_get",
      entry_point: "secondary_banner"
    });
  }

  render() {
      return html`
            <div class="banner-secondary">
                <img class="banner-img-secondary" src="${this.bannerSecondary}"/>
            </div>
            <div class="button-container-secondary">
                <button class="offer-btn" @click="${this.redirectToSimulation}">Dapatkan Penawaran</button>
            </div>
      `;
  }
}

customElements.define('landing-banner-secondary', LandingBannerSecondary);