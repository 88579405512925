
export class Aloproteksi{
    static sendEventGA4(data){
        dataLayer.push(function () {
            this.reset();
          });
        dataLayer.push({
            business: 'Proteksi',
            ...data,
        })
    }
}