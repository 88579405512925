import { LitElement, html, css, unsafeCSS } from 'lit';
import circleDotFull from '../../assets/images/circle-dot-full.svg'
import { Aloproteksi } from '../lib/aloproteksi';

class InsuranceListHospital extends LitElement {
    static properties = {
        hospitalList: {
            type: Object,
            attribute: 'hospital-list'
        },
        hospitalPartnerPdf: {
            type: String,
            attribute: 'hospital-partner-pdf'
        }
    }

    static styles = css`
        .background-dot-left {
            background-image: url(${unsafeCSS(circleDotFull)});
            background-repeat: no-repeat;
            background-position: -7% bottom;
        }

        .background-dot-right {
            background-image: url(${unsafeCSS(circleDotFull)});
            background-repeat: no-repeat;
            background-position: right -7% top;
        }

        .hospital-container {
            margin: 0 auto;
            margin-top: 84px;
            display: flex;
            justify-content: space-between;
            width: 75%;
        }

        .hospital-title {
            font-weight: 700;
            font-size: 28px;
            line-height: 42px;
            color: #424242;
            margin-bottom: 16px;
            width: 30%
        }

        .hospital-subtitle {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #7F7F7F;
            text-align: right;
            margin-top: 60px;
        }

        .hospital-image {
            width: 647px;
        }

        .other-hospital-button {
            background: none;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            margin-top: 64px;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #3570D2;
            padding: 12px 16px;
            border: 1px solid #3570D2;
            border-radius: 8px;
            align-self: flex-end;
        }
        
        .hospital-image-container {
            display: flex;
            flex-direction: column;
        }
    `

    openOtherHospitals() {
        window.open(this.hospitalPartnerPdf, '_blank').focus();
        Aloproteksi.sendEventGA4({
            event: "view_hospital_list",
        });
    }

    render() {
        return html`
            <div class="background-dot-left">
                <div class="background-dot-right">
                    <div class="hospital-container">
                        <div class="hospital-title">${this.hospitalList.list_title}</div>
                        <div class="hospital-image-container">
                            <img class="hospital-image" src="${this.hospitalList.list_image}">
                            <button class="other-hospital-button" @click="${this.openOtherHospitals}">
                                Lihat lima ribuan rumah sakit lainnya
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

}

customElements.define('insurance-list-hospital', InsuranceListHospital);


