import { LitElement, html, css, unsafeCSS } from 'lit';
import next from '../../assets/images/right-arrow.svg';
import prev from '../../assets/images/left-arrow.svg';
import ellipse from '../../assets/images/ellipse-testimony.svg';
import background_testimoni from '../../assets/images/left-double-quotation-mark.svg';
import { Aloproteksi } from '../lib/aloproteksi';
import { repeat } from 'lit/directives/repeat.js';


class InsuranceTestimony extends LitElement {

    static get properties() {
        return {
          data: {
            type: Object,
            attribute: 'insurance-testimoni'
        }
      };
    }

    render(){
        
        return html `

        <style include="alo-shared-style">
            .slider-insurance-hero {
                padding-top: 120px;
                padding-bottom: 244px;
                background-image: url(${unsafeCSS(ellipse)});
                background-repeat: no-repeat;
                background-position: center center;
            }
            .image-container,.indicator-btn-prev-next{
                display: flex;
                text-align: center;
                justify-content: center;
            }
            .slider {
                position: absolute;
                opacity: 0;
                display: flex;
                width: 445px;
                height: 354px;
                align-items: center;
                flex-direction: column;
            }
            .active-1 {
                position: relative !important;
                order:1;
                opacity: 1;
                width: 463px;
                margin-left:15px;
                margin-right:15px; 
                font-size: 28px;
            }
            .active-2  {
                position: relative !important;
                opacity: 1;
                order:2;
                width: 463px;
                margin-left:15px;
                margin-right:15px; 
                font-size: 28px;
            }
        
            .testimoni-container {
                width: 445px;
                background: no-repeat;
                background-size: 80px;
                height: 354px;
                border-radius: 5px;
                background-position-x: 15px;
                background-position-y: 15px;
                box-shadow: 0 2px 30px 0 rgb(0 0 0 / 10%);
                background-color: #ffffff;
            }
            .prev {
            }
            .next {
            }
            .title {
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.86;
                letter-spacing: normal;
                text-align: center;
                color: #3570d2;
            }
            .slider-image-wrapper {
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;
            }
            .subtitle {
                width: 381px;
                margin: 0 auto;
                margin-top: 8px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                letter-spacing: normal;
                text-align: center;
                color: #424242;
            }
            .image {
                margin-bottom:28px;
            }
            .btn {
                width: 56px;
                height: 56px;
                box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
                background-color: #ffffff;
                text-align: center;
                border-radius: 30px;
                z-index:15;
                background-position: center;
                background-repeat: no-repeat;
                cursor: pointer;
            }
            .button-indicator-container {
                margin-top: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .btn-slider-indicator-container {
                display: flex;
            }
            .btn-slider-indicator{
                cursor: pointer;
                margin-left:4px;
                margin-right:4px;
                width: 6px;
                height: 6px;
                border-radius: 5px;
                background-color: rgba(53, 112, 210, 0.26);
            }
            .indicator-active {
                width: 20px;
                height: 6px;
                border-radius: 4px;
                background-color: #3570d2;
            }
            .user-container{
                margin-top:32px;
                margin-left:32px;
                display: flex;
            }
            .image-avatar {
                width: 41px;
                border-radius: 60px;
                height: 41px;
                border: 3px solid #3570d2;
                background-size: cover;
            }
            .user-name-container {
                margin-left: 24px;
                text-align: left;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
            }
            .user-name {
                color: #3570d2;
                font-weight: 100;
            }
            .user-job {
                font-size: 16px;
                color: #7F7F7F;
            }
            
            .message {
                margin: 24px 32px 32px;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.44;
                letter-spacing: normal;
                color: #424242;
                text-align: center;
                height: 201px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                line-clamp: 8;
                -webkit-box-orient: vertical;
            }
            .prev-btn {
                margin-right: 5%;
            }
            .next-btn {
                margin-left: 5%;
            }
            .section-title {
                font-size: 32px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
                text-align: center;
                color: #040404;
            }
            .section-title-container{
                margin-bottom:48px;
            }
            .fade {
                -webkit-animation-name: fade;
                -webkit-animation-duration: 1.5s;
                animation-name: fade;
                animation-duration: 1.5s;
            }
            
            @-webkit-keyframes fade {
                from {opacity: .4}
                to {opacity: 1}
            }
            
            @keyframes fade {
                from {opacity: .4}
                to {opacity: 1}
            }
        </style>
        <div class="slider-insurance-hero">
        <div class="section-title-container">
            <div class="section-title">Kata mereka tentang Aloproteksi</div>
        </div>
        <div class="slider-image-wrapper">
        <div class="prev-btn-container">
            <div id="prevBtn" class="btn prev-btn" @click="${this.slide_left}" style="background-image:url(${prev})"></div>
        </div>
        <div id="imageContainer" class="image-container">
            ${repeat(
                this.data,(data) => html`
                <div id="slider-${data.order}" order="${data.order}" class="slider">
                <div class="image testimoni-container fade" style="background-image:url(${background_testimoni})"> 
                    <div class="message">
                    ${data.testimoni}
                    </div>
                    <div class="user-container">
                    <div class="image-avatar" style="background-image:url(${data.image_url})"></div>
                    <div class="user-name-container">
                        <div class="user-name">${data.name}</div>
                        <div class="user-job">${data.occupation}</div>
                    </div>
                    </div>
                </div>
                </div>
                `
         )}
        </div>
        <div class="next-btn-container">
            <div id="nextBtn" class="btn next-btn" @click="${this.slide_right}" style="background-image:url(${next})"></div>
        </div>
        </div>
        <div class="button-indicator-container">
            <div id="indicators" class="btn-slider-indicator-container">
            ${repeat(this.data, (data) => html`
                    <div class="btn-slider-indicator" order="${data.order}" @click="${this.setActive}"></div>
            `
            )}
            </div>
        </div>
        </div>

        `
    }
    
  
    connectedCallback(){
      super.connectedCallback();
      for (var i = 0 ; i< this.data.length; i++){
        this.data[i].order = i+1
      }
    }
  
    firstUpdated(){
      let slider = this.renderRoot.getElementById('imageContainer')
      let elList = slider.querySelectorAll(".slider")
      elList[0].classList.add("active-1");
      elList[1].classList.add("active-2");
      elList[2].classList.add("next");
      elList[elList.length -1].classList.add("prev");
      let indicators = this.renderRoot.querySelector("#indicators");
      let indicatorList = indicators.querySelectorAll(".btn-slider-indicator")
      indicatorList[0].classList.add("indicator-active")
    }
    
    slide_right(){
      let nextEl = this.shadowRoot.querySelector(".next");
      let prevEl = this.shadowRoot.querySelector(".prev");
      let activeElOne = this.shadowRoot.querySelector(".active-1");
      let activeElTwo = this.shadowRoot.querySelector(".active-2");
  
      let indicator = this.shadowRoot.querySelector(".indicator-active");
      indicator.classList.remove("indicator-active")
  
      activeElOne.classList.remove("active-1");
      activeElOne.classList.add("prev");
  
      activeElTwo.classList.remove("active-2");
      activeElTwo.classList.add("active-1");
  
      prevEl.classList.remove("prev");
  
      nextEl.classList.add("active-2");
      nextEl.classList.add("fadeInRight");
      nextEl.classList.remove("next");
  
      if (indicator.getAttribute('order') < this.data.length) {
        indicator.nextElementSibling.classList.add("indicator-active")
      }else {
        this.shadowRoot.querySelectorAll(".btn-slider-indicator")[0].classList.add("indicator-active");
      }
      if(nextEl.getAttribute('order') < this.data.length){
        nextEl.nextElementSibling.classList.add("next")
      }else {
        this.shadowRoot.querySelectorAll(".slider")[0].classList.add("next");
      }
      let direction = "Right"
      this._sendGATestimony(direction)
    }
  
    slide_left(){
      let nextEl = this.shadowRoot.querySelector(".next");
      let prevEl = this.shadowRoot.querySelector(".prev");
      let activeElOne = this.shadowRoot.querySelector(".active-1");
      let activeElTwo = this.shadowRoot.querySelector(".active-2");
  
      let indicator = this.shadowRoot.querySelector(".indicator-active");
      indicator.classList.remove("indicator-active")
  
      activeElOne.classList.remove("active-1");
      activeElOne.classList.add("prev");
  
      activeElTwo.classList.remove("active-2");
      activeElTwo.classList.add("active-1");
  
      prevEl.classList.remove("prev");
  
      nextEl.classList.remove("next");
      nextEl.classList.add("active-2");
  
      if(nextEl.getAttribute('order') > this.data.length-1){
        this.shadowRoot.querySelectorAll(".slider")[0].classList.add("next");
      }else {
        nextEl.nextElementSibling.classList.add("next")
      }
      if ((indicator.getAttribute('order')-1) > 0) {
        indicator.previousElementSibling.classList.add("indicator-active")
      }else {
        this.shadowRoot.querySelectorAll(".btn-slider-indicator")[this.data.length-1].classList.add("indicator-active");
      }
      let direction = "Left"
      this._sendGATestimony(direction)
    }
  
    setActive(e){
      this.shadowRoot.querySelector(".indicator-active").classList.remove("indicator-active");
      e.srcElement.classList.add("indicator-active");
      let order = parseInt(e.srcElement.getAttribute("order"));
  
      let nextEl = this.shadowRoot.querySelector(".next");
      nextEl.classList.remove("next");
      let prevEl = this.shadowRoot.querySelector(".prev");
      prevEl.classList.remove("prev");
      let activeElOne = this.shadowRoot.querySelector(".active-1");
      activeElOne.classList.remove("active-1");
      let activeElTwo = this.shadowRoot.querySelector(".active-2");
      activeElTwo.classList.remove("active-2")
  
      let totalItems = this.data.length;
      let slider = this.shadowRoot.querySelector('.image-container')
      let elList = slider.querySelectorAll(".slider")
  
      if(order == 1){
        elList[order-1].classList.add("active-1");
        elList[order].classList.add("active-2");
        elList[order+1].classList.add("next");
        elList[totalItems-1].classList.add("prev");
      }else if(order == totalItems){
        elList[order-1].classList.add("active-1");
        elList[0].classList.add("active-2");
        elList[1].classList.add("next");
        elList[order-2].classList.add("prev");
      }else{
        elList[order-2].classList.add("prev");
        elList[order-1].classList.add("active-1");
        elList[order].classList.add("active-2");
        if((order+1) == totalItems){
          elList[0].classList.add("next");
        }else{
          elList[order+1].classList.add("next");
        }
      }
    }
  
    _sendGATestimony(direction){
      Aloproteksi.sendEventGA4({    
        business: "Proteksi",
        direction: direction,
        event: "view_testimony"
      })
    }
}
  
  window.customElements.define('insurance-testimony', InsuranceTestimony);