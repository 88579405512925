import { LitElement, html, css } from "lit";

class ButtonGroup extends LitElement {
    static get properties() {
        return {
            benefitExclusion: {
                type: String,
                attribute: 'exclusion-information'
            },
            isPricingSection: {
                type: String,
                default: "false",
                state: true
            },
            priceGroups: {
                type: Array,
                attribute: 'price-groups'
            },
            contactSupport: {
                type: Object,
                attribute: 'contact-support'
            }
        }
    }

    static styles = css`
        .button-group-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }  

        .button-group {
            display: flex;
            flex-direction: row;
            background-color: #FAFAFA;
            border: 1px solid #ECECEC;
            margin: 32px 16px;
            border-radius: 8px;
            width: 55%;
            justify-content: space-between;
            margin: auto;
            margin-bottom: 115px;
        }
        
        .button {
            display: flex;
            align-items: center;
            padding: 32px 40px;
            gap: 32px;
            cursor: pointer;
        }
    
        .button-text {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            color: #424242;
        }

        .separator {
            border: 1px solid #ECECEC;
            width: 20%;
            text-align: center;
            margin: 48px auto;
        }
    `

    constructor() {
        super();
    }

    handleBenefitExclusion(event) {
        window.open(this.benefitExclusion, '_blank').focus();
    }

    handlePricing(event) {
        this.isPricingSection = !this.isPricingSection;
    }

    render() {
        return html`
        <div class="button-group-container">
            <div class="separator"></div>
            <div class="button-group" role="button">
                <div class="button" @click="${this.handlePricing}">
                    <p class="button-text">Lihat biaya pasangan dan anak yang ditanggung karyawan</p>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                            <path d="m376 816-56-56 184-184-184-184 56-56 240 240-240 240Z" fill="#424242" />
                        </svg>
                    </div>
                </div>
                
                <div class="button" role="button" @click="${this.handleBenefitExclusion}">
                    <p class="button-text">Lihat daftar pengecualian manfaat</p>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24">
                            <path d="m376 816-56-56 184-184-184-184 56-56 240 240-240 240Z" fill="#424242" />
                        </svg>    
                    </div>
                </div>
            </div>
        </div>

        <insurance-pricing-section isShown="${this.isPricingSection}" .priceGroups=${this.priceGroups} .contactSupport=${this.contactSupport}></insurance-pricing-section>
        
        `
    }
}

customElements.define('button-group', ButtonGroup)