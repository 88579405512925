import { LitElement, html, css } from "lit";
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { Aloproteksi } from '../lib/aloproteksi';

class InsurancePricingSection extends LitElement {

    static get properties() {
        return {
            isShown: {
                default: "false"
            },
            priceGroups: {
                type: Array
            },
            contactSupport: {
                type: Object
            },
            productImages: {
                type: Array,
                attribute: false,
                state: true
            },
            productPriceGroups: {
                type: Array,
                attribute: false,
                state: true
            }
        }
    }
    static styles = css`
        .modal-container {
            z-index: 1;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(0,0,0);
            background-color: rgba(0,0,0,0.4);
            transition: visibility 0.5s, opacity 0.2s;
        }

        .modal {
            background-color: #ffffff;
            border-radius: 8px;
            width: 69%;
            min-width: 978px;
        }

        .scroll{
            height: 90%;
            overflow: hidden scroll;
        }

        .modal-container[aria-hidden="true"] {
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
        }
      
        .modal-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 20px 28px;
        }
      
        .close-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
        }

        .close-button:focus {
            outline: #3570D2 5px auto;
        }
      
        .separator {
            background: linear-gradient(to bottom, #F3F8FF, #FFFFFF);
            height: 36px;
        }
      
        .modal-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 0 32px;
            margin: 0 48px;
        }
      
        .help-text {
            color: #424242;
            font-weight: 700;
            font-size: 14px;
            margin: 0;
        }
      
        .modal-body {
            display: flex;
            margin: 0 48px;
        }
      
        .contact-center {
            display: flex;
            flex-direction: row;
            width: 498px;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0 1px 10px rgba(66, 66, 66, 0.15);
            border-radius: 5px;
            background-color: #ffffff;
            font-size: 14px;
            padding: 24px;
            margin-top: 32px;
        }
      
        .contact-text {
            margin: 0;
            color: #3570D2;
            font-weight: bold;
        }
      
        .contact-description {
            margin: 0;
            color: #7F7F7F;
        }
      
        .contact-section-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            width: 54%;
        }
      
        .contact {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .contact p {
            line-height: 20px;
        }
      
        .contact-section-right {
            border: 1px solid #3570D2;
            border-radius: 8px;
            padding: 9px 12px 8px;
        }
        
        .contact-button {
            text-decoration: none;
            font-weight: bold;
            text-align: center;
            color: #3570D2;
        }
      
        .price-group {
            border-spacing: 0;
            table-layout: fixed;
            margin: 0 auto 32px;
            font-size: 14px;
            color: #424242;
        }
      
        .price-group > tbody > tr > td {
            border-right: 1px solid #92B9FA;
            border-top: 1px solid #92B9FA;
        }
      
        .price-group > thead > tr > th {
            text-align: center;
        }

        table tr:last-child td {
            border-bottom: 1px solid #92B9FA;
        }
        
        table tr:last-child td:first-child {
            border-bottom-left-radius: 12px;
        }
      
        table tr:last-child td:last-child {
            border-bottom-right-radius: 12px;
        }
        
        table tr:first-child td:first-child {
            border-top-left-radius: 12px;
        }

        table tr:first-child td:first-child,
        table tr:nth-child(2) td:first-child {
            font-size: 14px
        }
        
        table tr:first-child td:last-child {
            border-top-right-radius: 12px;
        }
      
        .age-range {
            width: 26%;
            max-width: 200px;
            background-color: #E5EFFF;
            font-weight: bold;
            padding: 19.5px 32px;
            font-size: 20px;
            text-align: left;
            font-size: 16px;
            line-height: 20px;
            border-left: 1px solid #92B9FA;
        }

        .price-group-row {
            text-align: center;
            padding: 0 22px
        }

        .price-group-row b {
            font-size: 16px;
        }

        .price-group-row:first-child {
            color: #7F7F7F;
        }

        .whatsapp-icon {
            width: 58px;
        }
        .ins-product-logo{
            height: 47px;
            margin-bottom: 25px;
        }
    `;

    handlesShowModal(event) {
        this.isShown = !this.isShown;
    }

    firstUpdated(){
        if(window.devicePixelRatio > 1 ){
            this.renderRoot.querySelector(".modal").classList.add("scroll")
        }
    }
    connectedCallback() {
        super.connectedCallback();

        this.transformPriceGroups();
    }

    transformPriceGroups() {
        this.productImages = this.priceGroups.reduce((acc, priceGroup) => [...acc, priceGroup['product_image']], [])
        const priceGroupsByAge = {};
        for (let product of this.priceGroups) {
            const priceGroups = product['price_groups'];
            for (let priceGroup of priceGroups) {
                const ageRange = priceGroup['title'];
                const price = priceGroup['price'];

                if (!priceGroupsByAge[ageRange]) {
                    priceGroupsByAge[ageRange] = [html`<td class="price-group-row">${unsafeHTML(price)}</td>`]
                } else {
                    priceGroupsByAge[ageRange] = [...priceGroupsByAge[ageRange], html`<td class="price-group-row">${unsafeHTML(price)}</td>`]
                }
            }
        }

        this.productPriceGroups = Object.entries(priceGroupsByAge)?.flatMap((priceGroup) => [[html`<td class="age-range">${priceGroup[0]}</td>`, ...priceGroup[1]]])
    }

    trackGtm(){
        Aloproteksi.sendEventGA4({
            event: "click_element",
            element: "wa_button_product_price"
        });
    }

    render() {
        return html`
            <div class="modal-container" aria-hidden="${!this.isShown}" role="dialog">
                <div class="modal">
                    <div class="modal-header">
                        <button type="button" class="close-button" @click="${this.handlesShowModal}">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
                                <path d="m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                            </svg>
                        </button>
                    </div>
                    <div class="separator"></div>
                    
                    <div class="modal-body">
                        <table class="price-group">
                            <thead>
                                <tr>
                                    <th></th>
                                    ${this.productImages?.map((image) => html`<th><img class="ins-product-logo" src="${image}" alt="Aloproteksi Product"></th>`)}
                                </tr>
                            </thead>
                                
                            <tbody>
                                ${this.productPriceGroups.map((priceGroup) => html`<tr>${priceGroup}</tr>`)}
                            </tbody>
                        </table>
                    </div>
                        
                    <div class="modal-footer">
                        <p class="help-text">Anda Butuh Bantuan?</p>
                         
                        <div class="contact-center">
                            <div class="contact-section-left">
                                <div class="whatsapp-logo">
                                    <img class="whatsapp-icon" src="${this.contactSupport['whatsapp_image_url']}" alt="Whatsapp icon">
                                </div>
        
                                <div class="contact">
                                    <p class="contact-text">Chat Kami di WhatsApp</p>
                                    <p class="contact-description">
                                        Alodokter siap menjawab pertanyaan Anda melalui chat
                                    </p>
                                </div>
                            </div>
                            
                            <div class="contact-section-right">
                                <a href="${this.contactSupport['phone_number']}" @click="${this.trackGtm}" class="contact-button" target="_blank">Chat Sekarang</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `
    }
}

customElements.define('insurance-pricing-section', InsurancePricingSection);